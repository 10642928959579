<template>
  <div class="root">
    <el-tabs v-model="activeName" :before-leave="beforeLeave">
      <template v-if="isWindowsAppV2">
        <el-tab-pane v-if="showCourseFile" label="课件" name="courseFile">
          <courseFile v-if="activeName == 'courseFile'"></courseFile>
        </el-tab-pane>
      </template>
      <template v-else>
        <!-- <el-tabs v-model="activeName" > -->
        <el-tab-pane v-if="showVideo" label="视频" name="first">
          <video-com v-if="activeName == 'first'"></video-com>
        </el-tab-pane>
        <el-tab-pane v-if="showPlayback" label="回放" name="second">
          <back-video v-if="activeName == 'second'"></back-video>
        </el-tab-pane>
        <el-tab-pane v-if="showFile" label="文档" name="third">
          <text-file v-if="activeName == 'third'"></text-file>
        </el-tab-pane>
        <el-tab-pane v-if="showCourseFile" label="课件" name="courseFile">
          <courseFile v-if="activeName == 'courseFile'"></courseFile>
        </el-tab-pane>
        <el-tab-pane v-if="showImages" label="图片" name="fifth">
          <image-file v-if="activeName == 'fifth'"></image-file>
        </el-tab-pane>
        <el-tab-pane v-if="showAudio" label="音频" name="sixth">
          <audio-file v-if="activeName == 'sixth'"></audio-file>
        </el-tab-pane>
        <el-tab-pane v-if="showWork" label="作业素材" name="seven">
          <div slot="label">
            作业素材
            <helpIcon
              content="指学员提交作业的文件内容，会占用网校存储"
            ></helpIcon>
          </div>
          <work-material v-if="activeName == 'seven'"></work-material>
        </el-tab-pane>
        <el-tab-pane v-if="showRecycle" label="回收站" name="fourth">
          <recovery-video v-if="activeName == 'fourth'"></recovery-video>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>
<script>
import bg from '@/mixin/background'
import videoCom from './components/video'
import backVideo from './backVideo'
import recoveryVideo from './recoveryVideo'
import textFile from './textFile'
import courseFile from './courseFile'
import imageFile from './imageFile'
import audioFile from './audioFile'
import helpIcon from '@/components/helpIcon'
import workMaterial from './components/workMaterial'
import { mapState } from 'vuex'

export default {
  components: {
    videoCom,
    backVideo,
    recoveryVideo,
    textFile,
    courseFile,
    imageFile,
    audioFile,
    helpIcon,
    workMaterial,
  },

  mixins: [bg],

  data() {
    return {
      activeName: '',
      tableData: [
        {
          id: 1,
          name: 'first',
          path: 'video',
        },
        {
          id: 2,
          name: 'second',
          path: 'playback',
        },
        {
          id: 4,
          name: 'third',
          path: 'file',
        },
        {
          id: 8,
          name: 'courseFile',
          path: 'courseFile',
        },
        {
          id: 3,
          name: 'fourth',
          path: 'recycle',
        },
        {
          id: 5,
          name: 'fifth',
          path: 'images',
        },
        {
          id: 6,
          name: 'sixth',
          path: 'audio',
        },
        {
          id: 7,
          name: 'seven',
          path: 'work',
        },
      ],
    }
  },

  computed: {
    ...mapState(['isWindowsAppV2']),

    showVideo() {
      return this.$store.state.roots.includes(157)
    },

    showPlayback() {
      return this.$store.state.roots.includes(158)
    },

    showFile() {
      return this.$store.state.roots.includes(162)
    },

    showCourseFile() {
      return this.$store.state.roots.includes(211)
    },

    showRecycle() {
      return this.$store.state.roots.includes(84)
    },

    showImages() {
      return this.$store.state.roots.includes(163)
    },

    showAudio() {
      return this.$store.state.roots.includes(164)
    },

    showWork() {
      return this.$store.state.roots.includes(166)
    },
  },

  watch: {
    $route: {
      handler(to) {
        let menu_id
        switch (to.params.type) {
          case 'video':
            menu_id = '157'
            break
          case 'playback':
            menu_id = '158'
            break
          case 'file':
            menu_id = '162'
            break
          case 'courseFile':
            menu_id = '211'
            break
          case 'images':
            menu_id = '163'
            break
          case 'audio':
            menu_id = '164'
            break
          case 'work':
            menu_id = '166'
            break
          case 'recycle':
            menu_id = '84'
            break
        }
        if (!this.$store.state.roots.includes(Number(menu_id))) {
          this.$router.push({ name: 'notFound' })
        }
      },
      immediate: true,
    },
  },

  created() {
    this.activeName = this.getActiveName(
      Number(this.gettype(this.$route.params.type))
    ).name
  },

  beforeRouteUpdate(to, from, next) {
    this.activeName = this.getActiveName(
      Number(this.gettype(to.params.type))
    ).name
    next()
  },

  methods: {
    gettype(value) {
      let type = 0
      switch (value) {
        case 'video':
          type = 1
          break
        case 'playback':
          type = 2
          break
        case 'file':
          type = 4
          break
        case 'courseFile':
          type = 8
          break
        case 'recycle':
          type = 3
          break
        case 'images':
          type = 5
          break
        case 'audio':
          type = 6
          break
        case 'work':
          type = 7
          break
      }
      return type
    },
    getActiveName(v) {
      let result = null
      for (const value of this.tableData) {
        if (value.id === v || value.name === v) {
          result = value
          break
        }
      }
      return result || this.tableData[0]
    },

    // 点击标签切换前
    beforeLeave(activeName) {
      this.$router.push({
        path: `/library/${this.getActiveName(activeName).path}`,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.root {
  margin: 20px;
  background: #fff;
}
/deep/ .el-tabs__nav-wrap {
  padding: 0 20px;
}
/deep/ .el-tabs__nav-wrap:after {
  height: 1px;
}
</style>