<template>
  <div class="audioVideo">
    <!--上传-->
    <!-- <help
      class="assistant-ps"
      text="全部视频包含所有分组的视频，视频移动到其他分组后，依然还在全部视频分组下，但不会重复占用存储容量"
    ></help> -->
    <upload v-if="uploadStart" :leftStorage="leftStorage" @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose" @getJgInfo="getJgInfo" :list="post.group_id" :menuName="isMenuName" :typeselection="1"
      :title="'上传视频(多选)'"></upload>

    <div class="header">
      <div title="点击上传" class="uploading" @click="uploadvideo">
        上传视频
      </div>
      <div class="boxTest">
        <div class="t1">
          <div class="c1" v-if="userInfo.version_id == 4">
            存储空间：{{ useStorage }}G
          </div>
          <div class="c1" v-else>
            存储空间：{{ useStorage }}G / {{ jigouStorage }}G
          </div>
          <div v-if="userInfo.version_id !== 4" class="c2" @click="$root.toggleUpdatePopup">
            扩容
          </div>
        </div>
        <div class="t3">
          <el-progress :percentage="percentage" color="#0aa29b" :show-text="false"></el-progress>
        </div>
      </div>

      <!-- <div class="store ml20">
        <el-select
          v-model="post.is_video_encryption"
          placeholder="全部加密状态"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div> -->

      <div class="store">
        <el-select v-model="post.state" placeholder="全部转码状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>

      <div class="input">
        <el-input :placeholder="post.searchType == 1 || post.searchType == ''
      ? '请输入视频名称搜索'
      : '请输入上传者名称搜索'
      " v-model="post.search" class="input-with-select">
          <el-select v-model="post.searchType" slot="prepend" placeholder="视频">
            <el-option label="视频" value="1"></el-option>
            <el-option label="上传者" value="2"></el-option>
          </el-select>
          <!-- 搜索 -->
        </el-input>
      </div>
    </div>
    <div class="contentVideo">
      <!-- 左侧菜单 -->
      <div style="heigth: 100%">
        <div class="leftMenu">
          <div class="footAddMenu" @click="AddMenu">
            <img src="../../assets/img/1.3.9/icon_xjfz备份.png" alt="" />
            <p>新建分组</p>
          </div>
          <div class="boxMenu">
            <transition-group name="flip-list">
              <div class="childMenu" v-for="(item, index) in menuList" :key="item.group_id"
                @click="cilckGroup(item.group_id, item.name)" @mouseleave="leaveGroup()"
                @mouseenter="mouseGroup(item.group_id)" :class="{ isbg: selectid == item.group_id }"
                :style="selectid == item.group_id ? '#F0F8FF' : ''">
                <img class="img" src="../../assets/img/1.3.9/icon_wjj.png" alt="" />
                <div class="flex-center namecount">
                  <div class="name" :title="`${item.name}(${item.count})`">
                    {{ item.name }}({{ item.count }})
                  </div>
                  <div class="contain-updown">
                    <div class="upDown" v-if="index != 0 && index != 1">
                      <div class="up item" @click.stop="
      index == 1 ? '' : groupSort(item.group_id, 1)
      ">
                        <img class="img2" src="~@ass/img/1.4.4.9/icon_sy_zh.png" alt="" v-if="index == 1" />
                        <img class="img2" src="~@ass/img/1.4.4.9/icon_sy.png" alt="" v-else />
                      </div>
                      <div class="down item" @click.stop="
      index == menuList.length - 1
        ? ''
        : groupSort(item.group_id, 2)
      ">
                        <img class="img2" src="~@ass/img/1.4.4.9/icon_xyzz_zh.png" alt=""
                          v-if="index == menuList.length - 1" />
                        <img class="img2" src="~@ass/img/1.4.4.9/icon_xy.png" alt="" v-else />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hoverDDD el-icon-more-outline" @click.stop="clickhoverDDD(item.group_id, index)">
                  <div class="childchildMenu">
                    <div @click.stop="reName(item.group_id, item.name)" :class="{ disabled: item.group_id == 0 }">
                      <span>编辑</span>
                    </div>
                    <div @click.stop="deleteMenu(item.name, item.group_id)" :class="{ disabled: item.group_id == 0 }">
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <div class="fff"></div>
      </div>
      <div class="rightMenu">
        <!-- 右侧内容视频 列表- 分页 -->
        <div class="rightCon ">
          <pagination2 :option="post" url="/DataBank/uploaded" ref="childVideo">
            <template v-slot:default="{ tableData }">
              <el-table :data="tableData" @sort-change="sortChange" @selection-change="handleSelectionChange"
                max-height:200 :header-cell-style="{
      background: 'rgba(245,245,245,1)',
      color: '#333333',
    }">
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="file_name" label="视频" min-width="380px" sortable="custom">
                  <template slot-scope="scope">
                    <div class="TitleVideo">
                      <div class="imgVideo">
                        <lazy class="img" :src="scope.row.video_img
      ? scope.row.video_img
      : require('../../assets/img/1.3.9/icon_spbs.png')
      " alt="" />
                        <div class="encryption" v-if="scope.row.zm_spec && scope.row.zm_spec != 5">
                          <span>{{ scope.row.zm_spec | getzm_spec }}</span>
                        </div>
                        <div class="videoTime">
                          <p>{{ scope.row.chapter_hour | formatTimeLength }}</p>
                        </div>
                      </div>
                      <div class="textVideo">
                        <p :title="scope.row.file_name">
                          {{ scope.row.file_name }}
                        </p>
                        <h4>上传者: {{ scope.row.uname }}</h4>
                        <h5>
                          <!-- <img v-if="scope.row.is_video_encryption == 1" src="../../assets/img/1.3.9.4/icon_dg.png"
                            alt="" />
                          <img v-else-if="scope.row.is_video_encryption == 2" src="../../assets/img/1.3.9.4/icon_cw.png"
                            alt="" />
                          <img style="height: 10px; width: 10px; margin-top: 2px" v-else
                            src="../../assets/img/1.3.9.4/icon_zmz.png" alt="" />
                          <span v-if="scope.row.is_video_encryption == 1">
                            已加密
                          </span>
                          <span v-if="scope.row.is_video_encryption == 2">
                            未加密
                          </span>
                          <span v-if="scope.row.is_video_encryption == 3">
                            加密中
                          </span> -->

                          <!-- 1未转码  2已转码  3转码中 -->
                          <img class="ml20" v-if="scope.row.transcode_status == 2"
                            src="../../assets/img/1.3.9.4/icon_dg.png" alt="" />
                          <img class="ml20" v-else-if="scope.row.transcode_status == 1"
                            src="../../assets/img/1.3.9.4/icon_cw.png" alt="" />
                          <img v-else class="ml20" style="height: 10px; width: 10px; margin-top: 2px"
                            src="../../assets/img/1.3.9.4/icon_zmz.png" alt="" />
                          <span v-if="scope.row.transcode_status == 2">
                            视频已转码
                          </span>
                          <span v-if="scope.row.transcode_status == 1">
                            视频未转码
                          </span>
                          <span v-if="scope.row.transcode_status == 3">
                            视频转码中
                          </span>
                        </h5>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="file_size" label="大小" sortable="custom" min-width="80">
                  <template slot-scope="scope">
                    <el-popover v-if="scope.row.is_video_encryption == 1 ||
      scope.row.transcode_status == 2
      " placement="bottom" width="200" trigger="hover">
                      <div>
                        <div style="margin-bottom: 10px">
                          源视频(MB)：{{ scope.row.video_size }}
                        </div>
                        <div>转码视频(MB)：{{ scope.row.zm_video_size }}</div>
                      </div>
                      <div slot="reference" class="cp">
                        {{ scope.row.size }}MB
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </el-popover>
                    <div v-else>{{ scope.row.size }}MB</div>
                  </template>
                </el-table-column>

                <el-table-column prop="add_time" label="上传时间" sortable="custom">
                  <template slot-scope="scope">
                    {{
      scope.row.add_time | formatTimeStamp('yyyy-MM-dd hh:mm')
    }}
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" min-width="95px">
                  <template slot-scope="scope">
                    <div class="btns">
                      <el-button :disabled="scope.row.is_video_encryption == 3" type="text" @click="
      cilckPreViewVideo(
        scope.row.is_video_encryption,
        scope.row.video_url,
        scope.row.transcode_status
      )
      ">
                        预览
                      </el-button>
                      <template>
                        <span class="space"></span>
                        <el-dropdown>
                          <span class="text">更多</span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click="
      download(
        scope.row.video_download_url,
        scope.row.file_name
      )
      ">
                              <!-- <a :href="scope.row.video_url" download="下载">下载</a> -->
                              <!-- <a href="blob:http://192.168.31.102:8080/d773837d-9400-4234-aa6d-351041a01cea" download="">点击下载</a> -->
                              <p v-if="scope.row.is_video_encryption == 2 ||
      scope.row.is_video_encryption == 1
      " @click="
      download(
        scope.row.video_download_url,
        scope.row.file_name
      )
      ">
                                下载
                              </p>

                              <el-button type="text" v-if="scope.row.is_video_encryption == 3" disabled>
                                <p class="ppp">下载</p>
                              </el-button>

                              <!-- <a class="aaaDownBtn" @click="downloadFile(scope.row.video_url,scope.row.file_name)">下载文件</a> -->
                            </el-dropdown-item>

                            <el-dropdown-item>
                              <p @click="zmDetail(scope.row)">转码详情</p>
                            </el-dropdown-item>

                            <el-dropdown-item>
                              <p @click="
      removeVideo(
        scope.row.video_id,
        scope.row.group_id
      )
      ">
                                移动到分组
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="post.group_id != 0 && post.group_id != -1">
                              <p @click="removeIsMenu(scope.row.video_id)">
                                移出该分组
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <p @click="
      renameVideo(
        scope.row.video_id,
        scope.row.file_name
      )
      ">
                                重命名
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <p @click="delVideo(scope.row.video_id)">删除</p>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <template v-slot:footer>
              <div class="footer">
                <span>已选择{{ selected.length || 0 }}条</span>
                <el-button class="ml20" type="" @click="removeIs" :disabled="selected.length == 0" size="medium">
                  批量移动
                </el-button>

                <el-button type="" size="medium" :disabled="selected.length == 0" @click="dels">
                  批量删除
                </el-button>
              </div>
            </template>
          </pagination2>
          <!--分页-->
        </div>
      </div>
    </div>
    <previewVideo v-if="previewSrc" :src="previewSrc" @close="preViewVideo"></previewVideo>

    <el-dialog title="新建分组" :visible.sync="AddMenudialogVisible" width="422px" :close-on-click-modal="false">
      <el-input type="text" placeholder="输入分组名称" v-model="AddMenutext" maxlength="10" show-word-limit></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddMenuCancel">取 消</el-button>
        <el-button type="primary" @click="AddMenuFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑分组" :visible.sync="renameMenudialogVisible" width="422px" :close-on-click-modal="false">
      <el-input type="text" placeholder="重命名分组名称" v-model="renameMenutext" maxlength="10" show-word-limit></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameMenuCancel">取 消</el-button>
        <el-button type="primary" @click="renameMenuFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="删除分组" :visible.sync="deleteMenudialogVisible" width="422px" :close-on-click-modal="false">
      <p>
        是否删除
        <a style="color: red">{{ deleteMenuName }}</a>
        ，删除后分组内的视频会释放到全部中！
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteMenuCancel">取 消</el-button>
        <el-button type="primary" @click="deleteMenuFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="移动到分组" :visible.sync="removeVideodialogVisible" width="422px" :close-on-click-modal="false">
      <el-select placeholder="请选择要移动到的分组" v-model="selectMenuId">
        <el-option v-for="item in menuListS" :disabled="item.isBg" :key="item.group_id"
          :label="item.name + '(' + item.count + ')'" :value="item.group_id"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <a class="aaaRed">* 同一个文件只支持在一个分组</a>
        <el-button @click="removeVideoCancel">取 消</el-button>
        <el-button type="primary" @click="removeVideoFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="文件重命名" :visible.sync="renameVideodialogVisible" width="522px" :close-on-click-modal="false">
      <el-input type="text" placeholder="输入新的文件名" v-model="renameVideotext" maxlength="50" show-word-limit></el-input>
      <span slot="footer" class="dialog-footer">
        <a class="aaaRedRame">* 重命名不会修改文件格式</a>
        <el-button @click="renameVideoCancel">取 消</el-button>
        <el-button type="primary" @click="renameVideoFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="选择加密转码分辨率" :visible.sync="encryptionVideodialogVisible" width="422px"
      :close-on-click-modal="false">
      <div class="encryptionVideoContent">
        <span>
          加密成功后在章节选择保护方案时，只作用保护方案，不会重新加密！
        </span>
        <div class="">
          <p @click="isencryptionVideoType = 1" :class="{ isencryptionVideoTypeBg: isencryptionVideoType == 1 }">
            高清(720p)
            <i class="el-icon-arrow-right"></i>
          </p>
          <p @click="isencryptionVideoType = 2" :class="{ isencryptionVideoTypeBg: isencryptionVideoType == 2 }">
            蓝光(1080p)
            <i class="el-icon-arrow-right"></i>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="encryptionVideoCancel">取 消</el-button>
        <el-button type="primary" @click="encryptionVideoFix">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 改为加密转码 -->
    <el-dialog title="温馨提示" :visible.sync="dialogVisible2" width="470px">
      <div class="dialogVisible2_main">
        <div class="font1">
          <!-- 改为加密转码，会覆盖全部普通转码的视频文件，加密后不可恢复普通转码，并且转码后不可重新转码，请确认规格！ -->
          改为加密转码，会覆盖全部普通转码的视频文件，请确认规格！
        </div>
        <div class="popover_main_center">
          <div class="popover_main_center_font1">选择规格</div>
          <div class="popover_main_center_font2" v-for="item in options3" :key="item.value" :class="item.disable
      ? 'noclik'
      : isclick == item.value
        ? 'popover_main_center_font2click'
        : ''
      " @click="!item.disable ? dianji(item.value) : ''">
            {{ item.label }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="jiami_zm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 转码详情 -->
    <transcoddetail v-if="status" @cancel="transcoddetail_cancel" @sure_zm="sure_zm" @changesource="changesource"
      @del="del" :type="1" :sure_zm_success="sure_zm_success" :delstatus="delstatus" @zm_encryption="zm_encryption"
      :dialogstatus.sync="status" titlename="视频" :information_find="information_find"
      :information_list="information_list" :firstlist="firstlist"></transcoddetail>
  </div>
</template>
<script>
import dataBase from '@/mixin/dataBase'
import previewVideo from '@cm/coursePop/previewVideo'
import upload from '@cm/base/UploadFile'
import { mapState } from 'vuex'
import transcoddetail from '@/views/audioVideo/components/transcoddetail'

export default {
  name: 'audioVideo',

  mixins: [dataBase],

  components: {
    upload,
    previewVideo,
    transcoddetail,
  },

  data() {
    return {
      selectid: 0,

      sure_zm_success: false,

      delstatus: false,

      //转码详情中选中的数据
      isselect: [],

      isclick: '',

      options3: [],

      //改为加密转码弹窗
      dialogVisible2: false,

      //该文件的信息
      information_find: {},

      information_list: [],

      firstlist: {},

      //详情弹窗
      status: false,

      isMenuName: '全部视频',

      videoIdArr: [],

      ncryptionVideoId: '',

      isencryptionVideoType: 0,

      renameVideoId: '',

      renameVideotext: '',

      isRemoveViedoId: [],

      selectMenuId: '',

      deleteMenuName: '',

      deleteMenuId: '',

      reNameid: '',

      isBg: true,

      selectedNum: 0,

      selected: '',

      menuList: [],

      menuListS: [],

      AddMenutext: '',

      renameMenutext: '',

      AddMenudialogVisible: false,

      renameMenudialogVisible: false,

      deleteMenudialogVisible: false,

      removeVideodialogVisible: false,

      renameVideodialogVisible: false,

      encryptionVideodialogVisible: false,

      options1: [
        {
          value: '0',
          label: '全部加密状态',
        },
        {
          value: '2',
          label: '未加密',
        },
        {
          value: '1',
          label: '已加密',
        },
        {
          value: '3',
          label: '加密中',
        },
      ],

      options: [
        {
          value: '0',
          label: '全部转码状态',
        },
        {
          value: '1',
          label: '视频未转码',
        },
        {
          value: '2',
          label: '视频已转码',
        },
        {
          value: '3',
          label: '视频转码中',
        },
      ],

      tableData: [],

      post: {
        type: 1,
        searchType: '1',
        search: '',
        state: '0',
        group_id: 0,
        order: '',
        sort: '',
        is_video_encryption: '0',
      },

      total: 0,

      useStorage: 0,

      jigouStorage: 0,

      leftStorage: 0,

      uploadStart: false,
    }
  },

  computed: {
    percentage() {
      return this.useStorage === 0
        ? 0
        : Math.floor((this.useStorage / this.jigouStorage) * 100)
    },
    ...mapState(['userInfo']),
  },

  created() {
    this.getJgInfo()
    //type=1用于获取 视频 菜单
    this.getGroupList(1)
  },

  methods: {
    groupSort(group_id, sort) {
      this.$http({
        url: '/dataBank/groupSort',
        data: {
          group_id: group_id,
          sort: sort,
        },
        callback: () => {
          this.getGroupList(1)
        },
      })
    },

    uploadvideo() {
      this.uploadStart = true
    },

    //关闭转码弹窗的回调
    transcoddetail_cancel() {
      this.getJgInfo()
      this.$refs.childVideo.reset()
    },

    //手动加密转码
    jiami_zm() {
      this.$store.dispatch('getAmount').then(() => {
        if (this.$store.state.amount <= 0) {
          this.$root.prompt({
            msg: '叮豆不足，无法上传和转码视频！',
          })
          return
        }
        if (this.isclick) {
          this.$http({
            url: '/DataBank/manualTranscode',
            data: {
              transcode_type: 1,
              video_id: this.isselect.video_id,
              video_spec: this.isclick,
              type: 1,
            },
            callback: ({ code, info }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: info,
                  type: 'success',
                })
                this.zmDetail(this.isselect)
              }
            },
            error: error => {
              this.$root.prompt({
                msg: error.info,
              })
            },
          })
        }
        this.dialogVisible2 = false
      })
    },
    //手动切换源
    changesource(val) {
      this.$http({
        url: '/DataBank/switchTranscode',
        data: {
          id: val,
        },
        callback: ({ code, info }) => {
          if (code == 200) {
            this.$root.prompt({
              msg: info,
              type: 'success',
            })

            this.zmDetail(this.isselect)
          }
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    //确定手动转码
    sure_zm(val) {
      if (val != 0) {
        this.$http({
          url: '/DataBank/manualTranscode',
          data: {
            transcode_type: 2,
            video_id: this.isselect.video_id,
            video_spec: val,
            type: 1,
          },
          callback: ({ code, info }) => {
            if (code == 200) {
              this.$root.prompt({
                msg: info,
                type: 'success',
              })
              this.zmDetail(this.isselect)
              this.sure_zm_success = true
            }
          },
          error: ({ info }) => {
            this.$root.prompt({
              msg: info,
            })
          },
        })
      }
    },
    dianji(val) {
      this.isclick = val
    },
    zm_encryption() {
      this.isclick = ''
      this.dialogVisible2 = true
    },
    //删除转码详情的数据
    del(val) {
      if (this.information_list.length == 1) {
        this.$root.prompt({
          msg: '该视频已设为播放源，无法删除！',
        })
        return
      }
      this.$confirm('是否要删除已转码的视频文件，不可恢复！', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http({
            url: '/DataBank/delTranscode',
            data: {
              id: val,
            },
            callback: ({ code }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: '删除成功',
                  type: 'success',
                })
                this.delstatus = true
                this.zmDetail(this.isselect)
              }
            },
            error: () => {
              this.delstatus = false
              this.$root.prompt({
                msg: '该视频已设为播放源，无法删除！',
              })
            },
          })
        })
        .catch(() => { })
    },
    //转码详情
    zmDetail(val) {
      this.isselect = val
      if (!val.vid) {
        this.$root.prompt({
          msg: '该视频为旧数据，资料库升级后无法对旧数据处理，没有转码详情',
        })
        return
      }
      this.$http({
        url: '/DataBank/transcodingDetails',
        data: {
          type: 1,
          video_id: val.video_id,
        },
        callback: ({ data: { find, list } }) => {
          this.information_find = find
          this.information_list = list
          this.firstlist = list[0]
          this.options3 = [
            {
              value: 1,
              label: '高清720p',
              disable: list.some(item => item.video_spec == 5),
            },
            {
              value: 2,
              label: '蓝光1080p',
              disable: list.some(item => item.video_spec == 6),
            },
          ]
        },
        error: () => {
          this.information = {}
        },
      })
      this.status = true
    },

    //下载
    download(src) {
      this.$store.dispatch('open', src)
    },

    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },

    cilckGroup(id, name) {
      this.selectid = id
      this.isMenuName = name
      //选中菜单的id请求菜单数据
      this.post.group_id = id
    },

    //鼠标进入后颜色
    mouseGroup(id) {
      const index = this.menuList.findIndex(item => item.group_id == id)
      var mouse = []
      mouse = document.getElementsByClassName('hoverDDD')
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        mouse[i].style.display = 'none'
      }
      if (index == 0 || index == 1) {
        return
      } else {
        mouse[index].style.display = 'block'
      }
      var isgroup = document.getElementsByClassName('childMenu')[index]
      isgroup.style.color = '#0aa29b'
    },

    //鼠标离开后清除颜色
    leaveGroup() {
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        document.getElementsByClassName('hoverDDD')[i].style.display = 'none'
        document.getElementsByClassName('childMenu')[i].style.color = ''
        document.getElementsByClassName('childchildMenu')[i].style.display =
          'none'
      }
    },
    //点击小点弹出的菜单传入菜单的id和第几个
    clickhoverDDD(id, index) {
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        document.getElementsByClassName('childchildMenu')[i].style.display =
          'none'
      }
      document.getElementsByClassName('childchildMenu')[index].style.display =
        'block'
    },

    deleteMenu(fileName, id) {
      this.deleteMenudialogVisible = true
      this.deleteMenuName = fileName
      this.deleteMenuId = id
    },

    getGroupList(type) {
      this.$http({
        url: '/DataBank/groupList?type=' + type,
        callback: ({ data }) => {
          this.menuList = data
          var data2 = _.merge([], data)
          data2.shift()
          this.menuListS = data2
        },
      })
    },
    //批量删除
    dels() {
      this.delVideo(this.videoIdArr)
    },
    //批量移动
    removeIs() {
      this.removeVideo(this.videoIdArr)
    },
    //多选后触发
    handleSelectionChange(val) {
      this.videoIdArr = []
      this.selected = val
      this.selectedNum = val.length
      //筛选出多选后视频id
      val.forEach(item => {
        this.videoIdArr.push(item.video_id)
      })
    },

    // type: 类型 1 音视频 2 回放管理 3 文档
    // sort:  排序   desc 最新 asc 最旧
    // search:  搜索关键字
    getData(data) {
      this.$http({
        url: '/DataBank/uploaded',
        data,
        isMultipleSelf: 'replacePrev',
        callback: ({ data, count }) => {
          this.total = Number(count)
          this.tableData = data
        },
        error: () => {
          this.total = 0
          this.tableData = []
        },
      })
    },

    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      if (value.prop == 'file_size') {
        order = 1
      } else if (value.prop == 'file_name') {
        order = 3
      } else {
        order = 2
      }
      this.post.order = order
      this.post.sort = sort
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.uploadStart = false
      this.getData(this.post)
      this.$refs.childVideo.reset()
      // //type=1用于获取 视频 菜单
      this.getGroupList(1)
    },

    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },

    // 添加分组菜单
    AddMenu() {
      this.AddMenudialogVisible = true
    },

    AddMenuCancel() {
      this.AddMenudialogVisible = false
    },
    AddMenuFix() {
      this.AddMenutext = this.trimStr(this.AddMenutext)
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+$/g.test(this.AddMenutext) ||
        this.AddMenutext == ''
      ) {
        this.$root.prompt('命名格式仅支持中英文和数字，空格无效，不能为空')
      } else {
        this.$http({
          url: '/DataBank/addGroup',
          data: {
            name: this.AddMenutext,
            type: 1,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.getGroupList(1)
              this.AddMenutext = ''
              this.AddMenudialogVisible = false
            }
          },
        })
      }
    },
    //重命名分组菜单
    //点击编辑
    reName(id, name) {
      this.renameMenudialogVisible = true
      this.reNameid = id
      this.renameMenutext = name
    },
    renameMenuCancel() {
      this.renameMenudialogVisible = false
    },
    renameMenuFix() {
      this.renameMenutext = this.trimStr(this.renameMenutext)
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+$/g.test(this.renameMenutext) ||
        this.renameMenutext == ''
      ) {
        this.$root.prompt('命名格式仅支持中英文和数字，空格无效，不能为空')
      } else {
        this.$http({
          url: '/DataBank/setGroup',
          data: {
            name: this.renameMenutext,
            group_id: this.reNameid,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.getGroupList(1)
              this.renameMenutext = ''
              this.renameMenudialogVisible = false
            }
          },
        })
      }
    },
    // 删除分组
    deleteMenuCancel() {
      this.deleteMenudialogVisible = false
    },
    deleteMenuFix() {
      this.deleteMenudialogVisible = false
      this.$http({
        url: '/DataBank/delGroup',
        data: {
          group_id: this.deleteMenuId,
        },
        callback: ({ code }) => {
          if (code == 200) {
            this.getGroupList(1)
            this.$refs.childVideo.reset()
          }
        },
      })
    },

    //删除视频单个
    delVideo(id) {
      this.$confirm('是否删除已选文件，删除后可在回收站中查看！', '删除文件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url: '/DataBank/deleteRecording',
          data: {
            ids: id,
            type: 1,
          },
          callback: () => {
            // this.tableData.splice(index, 1)
            // this.total -= 1
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.getJgInfo()
            this.$refs.childVideo.reset()
            this.getGroupList(1)
          },
          error: () => {
            this.$root.prompt('删除失败!')
          },
        })
      })
    },
    //移出该分组
    removeIsMenu(id) {
      this.$confirm(
        '确定后该文件会从当前分组消失，在全部视频分组下可查看到该文件！',
        '移出该分组',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/DataBank/moveGroup',
          data: {
            ids: id,
            type: 1,
            group_id: '0',
          },
          callback: () => {
            // this.tableData.splice(index, 1)
            // this.total -= 1
            this.$root.prompt({
              msg: '移出成功',
              type: 'success',
            })
            this.getJgInfo()
            this.$refs.childVideo.reset()
            this.getGroupList(1)
          },
          error: () => {
            this.$root.prompt('移出失败!')
          },
        })
      })
    },

    //移动视频
    removeVideo(id, group_id) {
      if (group_id == 0) {
        this.selectMenuId = ''
      } else {
        this.selectMenuId = group_id
      }

      this.isRemoveViedoId = id
      this.removeVideodialogVisible = true
    },
    removeVideoCancel() {
      this.removeVideodialogVisible = false
      this.selectMenuId = ''
    },
    removeVideoFix() {
      if (this.selectMenuId === '') {
        this.$confirm('请选择分组', '错误', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
      } else {
        this.$http({
          url: '/DataBank/moveGroup',
          data: {
            ids: this.isRemoveViedoId,
            type: 1,
            group_id: this.selectMenuId,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.selectMenuId = ''
              this.getGroupList(1)
              this.$refs.childVideo.reset()
              this.removeVideodialogVisible = false
            }
          },
        })
      }
    },
    //重命名视频
    renameVideo(id, name) {
      this.renameVideodialogVisible = true
      this.renameVideoId = id
      this.renameVideotext = name
    },
    renameVideoCancel() {
      this.renameVideodialogVisible = false
      this.renameVideotext = ''
    },
    renameVideoFix() {
      this.renameVideotext = this.trimStr(this.renameVideotext)
      var regTextChar = new RegExp('[\\\\*:<>/|?""]')
      if (
        regTextChar.test(this.renameVideotext) ||
        this.renameVideotext == ''
      ) {
        this.$root.prompt('文件名不能为空且不能包含特殊符号：\\ /:*?"<>|')
      } else {
        this.$http({
          url: '/DataBank/setFileName',
          data: {
            type: 1,
            id: this.renameVideoId,
            name: this.renameVideotext,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.$refs.childVideo.reset()
              this.renameVideodialogVisible = false
              this.renameVideotext = ''
            }
          },
        })
      }
    },

    //加密
    encryptionVideo(id, vid) {
      //有vid为腾讯上传视频,可以加密,没有是七牛
      if (vid) {
        this.encryptionVideodialogVisible = true
        this.ncryptionVideoId = id
      } else {
        this.$root.prompt(
          '该文件为加密功能上线前的数据，暂不支持加密，如需加密请重新上传！'
        )
      }
    },
    encryptionVideoCancel() {
      this.encryptionVideodialogVisible = false
    },
    encryptionVideoFix() {
      this.$http({
        url: '/DataBank/encryption',
        data: {
          type: 1,
          id: this.ncryptionVideoId,
          zm_spec: this.isencryptionVideoType,
        },
        callback: ({ code }) => {
          if (code == 200) {
            this.$refs.childVideo.reset()
            this.encryptionVideodialogVisible = false
            this.ncryptionVideoId = ''
            this.isencryptionVideoType = 0
          }
        },
      })
    },
    cilckPreViewVideo(isJm, url, transcode_status) {
      // is_video_encryption transcode_status
      //判断文件是否有播放源
      if (!url) {
        this.$root.prompt({
          msg:
            '当前视频无播放源，无法预览！如转码中，请等待转码完成；如未转码，点击转码详情进行转码',
        })
        return
      }
      //判断是否时加密中或者是转码中
      if (isJm == 3 || transcode_status == 3) {
        this.$root.prompt({
          msg: '视频正在处理，请稍后再试！',
        })
        return
      }
      //判断是否是加密完成
      if (isJm == 1) {
        this.$root.prompt('视频已加密,不支持直接在线播放')
      } else {
        this.preViewVideo(url)
      }
    },
  },
  //过滤器
  filters: {
    getzm_spec(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P '
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
        default:
          '-'
      }
      return text
    },
    getSize(val) {
      // if (val / 1024 >= 1) {
      //   return (val / 1024).toFixed(2) + 'G'
      // } else {
      return val + 'MB'
    },
    getStr(str) {
      function strlen(str) {
        var len = 0
        for (var i = 0; i < str.length; i++) {
          var c = str.charCodeAt(i)
          //单字节加1
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            len++
          } else {
            len += 2
          }
        }
        return len
      }
      if (strlen(str) >= 20) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
// ::v-deep .el-icon-arrow-right:before {
//   position: absolute;
//   left: 80px;
// }
.flip-list-move {
  transition: transform 0.35s;
}

::v-deep .dialogVisible2_class {
  &.el-dialog {
    width: 465px;
  }
}

.popover_main_center {
  // margin-bottom: 42px;
  margin-top: 28px;
  display: flex;
  align-items: center;

  .popover_main_center_font1 {
    margin-right: 21px;
    font-size: 14px;
    color: #333333;
  }

  .popover_main_center_font2 {
    text-align: center;
    font-size: 12px;
    color: #333333;
    line-height: 30px;
    width: 74px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    margin-left: 10px;
    cursor: pointer;
  }

  .popover_main_center_font2click {
    border: 1px solid #1b9d97;
    // background: url(../../assets/img/1.3.9.4/icon_jbxz.png) no-repeat bottom
    //   right;
  }

  .noclik {
    color: #999999;
    border: 1px solid #eaeaea;
    cursor: no-drop;
  }
}

.dialogVisible2_main {
  .font1 {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
  }
}

.ppp {
  width: 90px;
  text-align: left;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

::v-deep .aaaRed {
  width: 166px;
  height: 16px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 16px;
  margin-right: 48px;
}

::v-deep .aaaRedRame {
  width: 166px;
  height: 16px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 16px;
  margin-right: 182px;
}

body {
  background: rgb(245, 245, 245) !important;
}

.el-dropdown-menu__item {
  width: 80px;
}

.adminPage {
  background: rgb(245, 245, 245);
}

::v-deep #app {
  background: rgb(245, 245, 245);
  min-height: 100%;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 16px;
    margin-right: 180px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;
  position: absolute;
  top: 0px;
  left: -60px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.VideoSizeBox {
  height: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin: 10px 0;

    a {
      color: #999;
    }
  }
}

.TitleVideo {
  display: flex;

  .imgVideo {
    background: #000;
    margin-right: 10px;
    width: 141px;
    height: 80px;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .img {
      border-radius: initial;
      margin: 0 auto;
      width: auto;
      height: 100%;
      margin: 0 auto;
    }

    .encryption {
      position: absolute;
      top: 0;
      width: 45px;
      height: 22px;
      border-radius: 0px 0px 11px 0px;
      overflow: hidden;
      background: #fa6400;

      span {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 18px;
        margin-left: 4px;
      }
    }

    .videoTime {
      width: 100%;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      text-align: center;

      p {
        height: 12px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .textVideo {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
      min-width: 168px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h4 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 14px;
      margin-top: 15px;
      font-weight: normal;
    }

    h5 {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 14px;
      margin-top: 13px;
      font-weight: normal;

      img {
        margin-right: 6px;
        height: 8px;
        width: 8px;
      }
    }
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 20px;

  .boxMenu::-webkit-scrollbar {
    display: none;
  }

  .fff {
    width: 250px;
    height: 45px;
    visibility: hidden;
  }

  .leftMenu {
    width: 250px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    position: relative;
    max-height: 420px;
    min-height: 330px;
    padding-bottom: 80px;
    overflow-x: hidden;
    overflow-y: auto;
    @extend %scroll;
    margin-top: 60px;

    .boxMenu {
      margin-top: 36px;
      width: 250px;

      .childMenu {
        width: 250px;
        height: 36px;
        // background: #F0F8FF;
        cursor: pointer;

        .img {
          width: 15px;
          height: 14px;
          position: relative;
          top: 12px;
          left: 20px;
        }

        .namecount {
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          // color: #0AA29B;
          position: relative;
          left: 40px;
          bottom: 1px;
          width: 164px;

          .name {
            width: 103px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 15px;
            margin-right: 5px;
          }

          .contain-updown {
            display: none;

            .upDown {
              display: flex;
              align-items: center;

              .item {
                width: 20px;
                height: 20px;

                .img2 {
                  height: 100%;
                  width: 100%;
                }
              }

              .up {
                margin-right: 10px;
              }
            }
          }
        }

        .hoverDDD {
          display: none;
          width: 25px;
          height: 26px;
          margin-left: 210px;
          margin-top: -16px;
          position: relative;
        }

        .childchildMenu {
          position: absolute;
          z-index: 999;
          width: 100px;
          height: 88px;
          right: 0;
          box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          border: 1px solid #f7f7f7;
          display: none;

          div:hover {
            background-color: #f0f8ff;
          }

          div {
            width: 100px;
            height: 44px;
            background: #fff;
            text-align: center;

            span {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #4a4a4a;
              line-height: 44px;
            }
          }
        }

        &:hover .contain-updown {
          display: block !important;
        }
      }
    }

    .footAddMenu {
      position: absolute;
      top: 0;
      width: 250px;
      height: 36px;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 11px;
        left: 82px;
      }

      p {
        height: 13px;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 36px;
      }
    }
  }

  .rightMenu::-webkit-scrollbar {
    display: none;
  }

  .rightMenu {
    flex: 1;
    position: relative;
    padding-right: 20px;
    overflow-x: hidden;
    padding-top: 60px;
  }
}

.audioVideo {
  background: #fff;
}

::v-deep .audioVideo .el-table {
  padding: 0 20px;
}

.header {
  display: flex;
  min-width: 930px;
  margin-top: 20px;
  padding-right: 20px;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    // margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .boxTest {
    flex: 1;

    // margin-top: 20px;
    .t1 {
      width: 228px;
      display: flex;
      align-items: center;

      .c1 {
        flex: 1;
        color: #333333;
        font-size: 13px;
      }

      .c2 {
        font-size: 13px;
        font-weight: bold;
        color: #0aa29b;
        font-size: 13px;
        cursor: pointer;
      }
    }

    .t3 {
      width: 228px;
      margin-top: 14px;
    }
  }

  .store {
    width: 160px;
    height: 36px;
    border-radius: 4px;
    margin-right: 20px;
  }

  ::v-deep .input {
    // margin-left: 20px;

    .el-input-group__prepend {
      width: 55px;
    }
  }
}

.btns {
  @extend %btns;
  position: relative;

  p {
    width: 136px;
  }
}

.moreRightMenuAll {
  width: 136px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  position: absolute;
  left: -45px;
  z-index: 9;
  display: none;

  div {
    height: 40px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    p {
      margin-left: 20px;
      line-height: 40px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #4a4a4a;
    }
  }
}
</style>
