<template>
  <div class="transcoddetail">
    <el-dialog :title="titlename + '-转码详情'" class="dialogVisible" width="900px" :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)" v-if="dialogstatus" @close="cancel">
      <div slot="title" class="flex-center">
        <div class="title_font">
          {{ titlename + '-转码详情' }}
        </div>
        <div class="redcolor tip">转码规格480p和360p限时免费</div>
      </div>

      <div class="main">
        <div class="heard">
          <div class="top">
            <div class="font1" v-if="information_find.file_name" :class="{
        mt15: !information_find.is_show || type == 2,
        mb10: !information_find.is_show || type == 2,
      }">
              {{ type == 2 ? '回放视频' : '源视频' }}：
              {{ information_find.file_name }}
            </div>

            <div class="font2" v-if="information_find.is_show">
              <div v-if="!isStatus3">
                <helpIcon style="margin-right: 10px"
                  prompt="指学员端观看的视频文件，有多个普通转码文件时，可以设置学员观看的视频规格；加密后无法切换播放源。注意：下载转码类型不支持设置为播放源！"></helpIcon>
                <span>播放源：</span>
                <el-select v-model="information_find.zm_spec" style="width: 133px" size="mini" placeholder="请选择"
                  @change="changesource">
                  <el-option v-for="item in informationListFirstTranscodeType" :key="item.id" :label="item.video_spec == 1
          ? '高清720p'
          : item.video_spec == 2
            ? ' 蓝光1080p'
            : item.video_spec == 3
              ? ' 流畅360p'
              : item.video_spec == 4
                ? '标清480p'
                : item.video_spec == 5
                  ? '高清720p-加密'
                  : ' 蓝光1080p-加密'
        " :value="item.video_spec"></el-option>
                </el-select>
              </div>
              <!-- <div class="nobeclick" v-if="isStatus3" @click="nobeclick"></div> -->

              <el-popover placement="bottom-end" title="重新转码" width="423" trigger="click" ref="popover1">
                <div class="popover_main">
                  <div class="popover_main_font1">
                    重新转码会生成新的转码文件，占用网校存储；同一规格只可转码一次！
                  </div>
                  <div class="popover_main_center">
                    <div class="popover_main_center_font1">选择规格</div>
                    <div class="popover_main_center_font2" v-for="item in options" :key="item.value" :class="item.disable ||
          (charge_mode == 2 &&
            (item.value == 1 || item.value == 2))
          ? 'noclik'
          : isclick == item.value
            ? 'popover_main_center_font2click'
            : ''
        " @click="
        item.disable ||
          (charge_mode == 2 &&
            (item.value == 1 || item.value == 2))
          ? ''
          : dianji(item.value)
        ">
                      {{ item.label }}
                    </div>
                  </div>
                  <div class="popover_main_buttom">
                    <div class="left">
                      <el-button v-if="false && active == 1" type="text" @click="$emit('zm_encryption')" size="medium"
                        :disabled="is_drm == 2">
                        改为加密转码
                      </el-button>
                    </div>
                    <div class="right">
                      <el-button size="medium" @click="close">取消</el-button>
                      <el-button size="medium" type="primary" @click="sure_zm">
                        确定
                      </el-button>
                    </div>
                  </div>
                </div>
                <el-button slot="reference" type="text" style="margin-left: 20px" @click="again">
                  重新转码
                </el-button>
              </el-popover>

              <!-- <div
                class="Deficiency"
                v-if="this.$store.state.userInfo.t_amount <= 0"
                @click="Deficiency"
              ></div> -->
            </div>
          </div>
          <div class="flex-center">
            <div v-if="type == 2" class="bottom" style="margin-right: 40px">
              所属章节：{{ information_find.name }}
            </div>
            <div class="bottom" v-if="information_find.video_size">
              大小：{{ information_find.video_size }}MB
            </div>
          </div>
        </div>
      </div>
      <template>
        <el-table :empty-text="type == 1 ? '暂无转码数据' : '暂无转码数据'" :data="information_list" :header-cell-style="{
        background: 'rgba(245,245,245,1)',
        color: '#333333',
        height: '40px',
      }" style="width: 100%" :class="{ h300: !information_find.is_show }">
          <!-- <el-table-column prop="transcode_type" label="转码类型">
            <template slot-scope="{ row }">
              {{ row.transcode_type | get_transcode_type }}
            </template>
</el-table-column> -->
          <el-table-column prop="video_spec" label="转码规格" min-width="120px">
            <template slot-scope="{ row }">
              <div>
                {{ row.video_spec | get_video_spec }}
              </div>
              <div style="color: #0aa29b; font-size: 12px" v-if="row.select">
                已设为播放源
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="video_size" label="大小(MB)">
            <template slot-scope="{ row }">
              <span v-if="row.video_size">{{ row.video_size }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="转码开始/结束时间" min-width="150px">
            <template slot-scope="{ row }">
              <div>{{ row.transcode_s_time | formatTimeStamp }}</div>
              <div v-if="row.transcode_e_time > 0">
                {{ row.transcode_e_time | formatTimeStamp }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="转码时长" min-width="100">
            <template slot-scope="{ row }">
              <span v-if="row.transcode_e_time > 0">
                {{
        (row.transcode_e_time - row.transcode_s_time)
        | formatTimeLength
      }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="状态">
            <template slot-scope="{ row }">
              {{ row.transcode_status | get_transcode_status }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" @click="del(row)" :disabled="row.transcode_status == 3">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex-center mt20" v-if="!information_find.is_show">
          <img src="../.././../assets/img/1.3.9.4/icon_gth.png" alt="" />
          <div class="tip">
            加密转码暂不支持重新转码、设置播放源和删除加密转码文件！
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
export default {
  name: 'transcoddetail',
  data() {
    return {
      //1开启2，关闭
      is_drm: this.$store.state.userInfo.jg_is_drm,
      // 1按量收费 2不按量收费
      charge_mode: this.$store.state.userInfo.charge_mode,
      //被删除的数据的转码类型
      isdelspec: 0,
      isclick: 0,
      //弹窗的控制
      dialogstatus: true,
      options: [
        {
          value: 3,
          label: '流畅360p',
          disable: false,
        },
        {
          value: 4,
          label: '标清480p',
          disable: false,
        },
        {
          value: 1,
          label: '高清720p',
          disable: false,
        },
        {
          value: 2,
          label: '蓝光1080p',
          disable: false,
        },
      ],
    }
  },

  components: {
    helpIcon,
  },

  filters: {
    get_transcode_type(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '加密转码'
          break
        case 2:
          text = '普通转码'
          break
        case 3:
          text = '下载转码'
          break
      }
      return text
    },

    get_video_spec(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '高清720p'
          break
        case 2:
          text = '蓝光1080p'
          break
        case 3:
          text = '流畅360p'
          break
        case 4:
          text = '标清480p'
          break
        case 5:
          text = '高清720p-加密'
          break
        case 6:
          text = '蓝光1080p-加密'
          break
      }
      return text
    },

    get_transcode_status(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '未转码'
          break
        case 2:
          text = '转码完成'
          break
        case 3:
          text = '转码中'
          break
      }
      return text
    },
  },

  props: {
    //1,直播课  2，公开课
    active: {
      type: Number,
      default: 1,
    },

    //头部title名
    titlename: String,

    //获取的头部信息
    information_find: {
      type: Object,
      default: () => { },
    },

    //获取的列表信息
    information_list: {
      type: Array,
      default: () => { },
    },

    //获取列表中的第一个数据
    firstlist: {
      type: Object,
      default: () => { },
    },

    //1，视频 2，回放
    type: {
      type: Number,
      default: 0,
    },

    //获取删除的回调信息
    delstatus: {
      type: Boolean,
      default: false,
    },

    sure_zm_success: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    //获取播放源的选择项
    informationListFirstTranscodeType() {
      return this.information_list.filter(f => f.transcode_type != 3)
    },
    //判断列表中是否有正在转码的数据
    isStatus3() {
      return (
        this.information_list.filter(f => f.transcode_status == 3).length > 0
      )
    },
  },

  methods: {
    //删除按钮的点击时间
    del(val) {
      this.$emit('del', val.id)
      this.isdelspec = val.video_spec
    },

    //手动切换源
    changesource(val) {
      let id = this.information_list.filter(f => f.video_spec == val)[0].id
      this.$emit('changesource', id)
    },

    //重新转码中的取消按钮
    close() {
      this.$refs.popover1.showPopper = false
    },

    //重新转码中的确定转码按钮
    sure_zm() {
      this.$store.dispatch('getAmount').then(() => {
        if (this.$store.state.amount <= 0) {
          this.$root.prompt({
            msg: '叮豆不足，无法上传和转码视频！',
          })
          return
        }
        //判断是否有转码过了，转过了就重置一下
        if (this.isclick == 0) {
          this.$emit('sure_zm', this.isclick)
          this.$refs.popover1.showPopper = false
        } else {
          this.$emit('sure_zm', this.isclick)
          this.$refs.popover1.showPopper = false
        }
      })
    },

    //点击重新转码按钮
    again() {
      this.isclick = 0
      let list = this.options.map(m => m.value)
      // console.log(this.information_list)
      this.information_list.forEach(item => {
        if (item.transcode_type != 3) {
          var index = list.indexOf(Number(item.video_spec))
          if (index > -1) {
            this.options[index].disable = true
          }
        }
      })
      if (this.delstatus) {
        this.options.forEach(item => {
          if (item.value == this.isdelspec) {
            item.disable = false
          }
        })
      }
    },

    //加密转码中选择规格的点击事件
    dianji(val) {
      this.isclick = val
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="scss" scoped>
// .Deficiency {
//   width: 88px;
//   height: 50px;
//   position: absolute;
//   right: 23px;
// }
::v-deep input::-webkit-input-placeholder {
  color: #0aa29b;
}

::v-deep input::-moz-input-placeholder {
  color: #0aa29b;
}

::v-deep input::-ms-input-placeholder {
  color: #0aa29b;
}

// .nobeclick {
//   height: 50px;
//   width: 148px;
//   // background-color: red;
//   position: absolute;
//   left: 627px;
// }
.greycolor {
  color: #c0c4cc;
}

::v-deep .el-table__empty-text {
  width: 74%;
}

.h300 {
  height: 300px !important;
}

.title_font {
  font-size: 14px;
  color: #333333;
}

.tip {
  font-size: 13px;
  color: #ff3535;
  line-height: 13px;
  margin-left: 10px;
}

::v-deep .el-table__body-wrapper {
  height: calc(100% - 48px);
  overflow-y: auto;
  @extend %scroll;
}

::v-deep .el-table {
  height: 358px;
}

::v-deep .el-dialog {
  height: 550px;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #1b9d97;
}

::v-deep .el-input__inner {
  font-size: 14px;
  border: 0;
  color: #1b9d97;
  background: #f5f5f5;
  padding-right: 0;
  padding-left: 0;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 20px;
}

.popover_main {
  .popover_main_font1 {
    font-size: 14px;
    color: #666666;
  }

  .popover_main_center {
    margin-bottom: 42px;
    margin-top: 28px;
    display: flex;
    align-items: center;

    .popover_main_center_font1 {
      margin-right: 21px;
      font-size: 14px;
      color: #333333;
    }

    .popover_main_center_font2 {
      text-align: center;
      font-size: 12px;
      color: #333333;
      line-height: 30px;
      width: 74px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      margin-left: 10px;
      cursor: pointer;
    }

    .popover_main_center_font2click {
      border: 1px solid #1b9d97;
      // background: url(../../../assets/img/1.3.9.4/icon_jbxz.png) no-repeat
      //   bottom right;
    }

    .noclik {
      color: #999999;
      border: 1px solid #eaeaea;
      cursor: no-drop;
    }
  }

  .popover_main_buttom {
    display: flex;
    justify-content: space-around;

    .left {
      margin-right: 176px;
    }
  }
}

.transcoddetail {
  .main {
    box-sizing: border-box;

    .heard {
      font-size: 14px;
      background: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #f5f5f5;
      margin-bottom: 20px;
      padding: 0px 20px 20px;
      box-sizing: border-box;

      .top {
        display: flex;
        margin-top: 4px;
        align-items: center;
        justify-content: space-between;

        .font1 {
          color: #333333;
          line-height: 14px;
          width: 508px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .font2 {
          align-items: center;
          display: flex;
          color: #1b9d97;
          line-height: 14px;
        }
      }

      .bottom {
        color: #666666;
        line-height: 14px;
        margin-top: 10px;
      }
    }
  }
}
</style>
