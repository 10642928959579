<template>
  <div id="workMaterial">
    <div class="header">
      <div class="left">
        <el-radio-group
          v-model="post.content_type"
          @change="radioChange"
          class="mr30"
        >
          <el-radio-button label="4">视频</el-radio-button>
          <el-radio-button label="2">图片</el-radio-button>
          <el-radio-button label="3">音频</el-radio-button>
        </el-radio-group>

        <div class="boxTest">
          <div class="t1">
            <!-- <div class="c1">存储空间：{{ useStorage }}G</div> -->
            <div class="c1">
              存储空间：{{ useStorage }}G / {{ jigouStorage }}G
            </div>
            <div class="c2" @click="$root.toggleUpdatePopup">扩容</div>
          </div>
          <div class="t3">
            <el-progress
              :percentage="percentage"
              color="#0aa29b"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </div>
      <div class="input">
        <el-input
          :placeholder="
            post.searchType == 1 ? '输入文件名称搜索' : '输入上传者名称搜索'
          "
          class="input-with-select"
          v-model="post.search"
        >
          <!-- 搜索 -->
          <el-select
            class="selectstyle"
            slot="prepend"
            placeholder="视频"
            v-model="post.searchType"
          >
            <el-option label="文件" value="1"></el-option>
            <el-option label="上传者" value="2"></el-option>
          </el-select>
        </el-input>
      </div>
    </div>
    <div class="main">
      <pagination2
        :option="post"
        url="/DataBank/homeworkMaterials"
        ref="childDialogBox"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            ref="dialogTable"
            :data="tableData"
            @sort-change="sortChange"
            @selection-change="handleSelectionChange"
            max-height:200
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              label="文件"
              sortable="custom"
              min-width="300px"
              prop="file_name"
            >
              <template slot-scope="scope">
                <div class="TitleVideo">
                  <div
                    class="imgVideo"
                    :style="post.content_type == '3' ? 'width:80px' : ''"
                  >
                    <img
                      class="img"
                      v-if="
                        post.content_type == '4' || post.content_type == '3'
                      "
                      :src="
                        post.content_type == '4'
                          ? require('@ass/img/1.3.9/icon_spbs.png')
                          : require('@ass/img/1.4.0.4/icon_yptb@2x.png')
                      "
                      alt=""
                    />
                    <lazy
                      v-else
                      class="img"
                      :src="scope.row.content_url"
                      alt=""
                      :style="post.content_type == '3' ? 'margin:0; ' : ''"
                    />
                    <div
                      class="muisctip"
                      v-if="scope.row.homework_result_content_id == vedio"
                    >
                      <img src="~@ass/img/1.4.1.2/icon_bfz@2x.png" alt="" />
                    </div>
                    <div
                      class="encryption"
                      v-if="scope.row.zm_spec && scope.row.zm_spec != 5"
                    >
                      <span>{{ scope.row.zm_spec | getzm_spec }}</span>
                      <!-- <span v-if="scope.row.zm_spec == 1">720p</span>
                          <span v-else-if="scope.row.zm_spec == 2">1080p</span>
                          <span v-else>-</span> -->
                    </div>
                    <div class="videoTime" v-if="post.content_type == '4'">
                      <p>{{ scope.row.chapter_hour | formatTimeLength }}</p>
                    </div>
                  </div>
                  <div class="textVideo">
                    <p :title="scope.row.file_name">
                      {{ scope.row.file_name }}
                    </p>
                    <h4>上传者: {{ scope.row.uname }}</h4>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="来源" min-width="100px">
              <template slot-scope="{ row }">
                <p>作业：{{ row.homework_name || '-' }}</p>
                <p>课程：{{ row.course_name || '-' }}</p>
                <p>章节：{{ row.schedule_name || '-' }}</p>
              </template>
            </el-table-column>

            <el-table-column
              label="格式"
              prop="file_format"
              v-if="post.content_type != '4'"
            ></el-table-column>

            <el-table-column label="大小" sortable="custom" prop="size">
              <template slot-scope="{ row }">{{ row.size }}MB</template>
            </el-table-column>

            <el-table-column label="上传时间" sortable="custom" prop="c_time">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>

            <el-table-column
              label="上传者"
              sortable="custom"
              prop="uname"
              v-if="post.content_type != '4'"
            ></el-table-column>

            <el-table-column label="操作" min-width="100px">
              <template slot-scope="{ row }">
                <div class="btns">
                  <el-button
                    type="text"
                    size="medium"
                    @click="preview(row)"
                    v-if="post.content_type != '2'"
                  >
                    预览
                  </el-button>
                  <div style="position: relative;" v-else>
                    <el-image
                      style="width: 30px; height: 36px; z-index: 9999;overflow: hidden;"
                      :src="row.content_url"
                      :preview-src-list="[row.content_url]"
                    ></el-image>
                    <el-button type="text" size="medium" class="pictureyulan">
                      预览
                    </el-button>
                  </div>

                  <template v-if="showDeleteBtn">
                    <span class="driver"></span>
                    <el-button
                      type="text"
                      size="medium"
                      @click="del(row.homework_result_content_id)"
                    >
                      删除
                    </el-button>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-slot:footer>
          <div class="footer">
            <div class="font1">
              已选择{{ homework_result_content_ids.length }}条
            </div>
            <el-button
              v-if="showDeleteBtn"
              style="width: 100px"
              size="medium"
              @click="del(2)"
            >
              批量删除
            </el-button>
          </div>
        </template>
      </pagination2>
    </div>
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="preViewVideo"
    ></previewVideo>
  </div>
</template>

<script>
import previewVideo from '@cm/coursePop/previewVideo'
export default {
  name: 'workMaterial',
  data() {
    return {
      vedio: '',
      previewSrc: '',
      homework_result_content_ids: [],
      useStorage: 0,
      jigouStorage: 0,
      leftStorage: 0,
      post: {
        content_type: '4',
        searchType: '1',
        search: '',
        order: '',
        sort: '',
      },
    }
  },
  components: {
    previewVideo,
  },
  created() {
    this.getJgInfo()
  },
  computed: {
    percentage() {
      return this.useStorage === 0
        ? 0
        : Math.floor((this.useStorage / this.jigouStorage) * 100)
    },
    showDeleteBtn() {
      return this.$store.state.roots.includes(122)
    },
  },
  methods: {
    radioChange() {
      this.$notify.closeAll()
      this.vedio = ''
      this.previewSrc = ''
    },
    //音频预览
    openAudio(file_name, content_url) {
      let name = file_name
      let url = content_url
      this.$notify.closeAll()
      this.$notify({
        customClass: 'popAudio1404',
        offset: 100,
        duration: 0,
        title: '正在播放:' + name,
        dangerouslyUseHTMLString: true,
        message: `<audio class="audio" src=${encodeURI(
          url
        )} autoplay controls="controls">`,
        onClose: this.closefun,
      })
      setTimeout(() => {
        document.querySelector('.el-notification__title').title = name
      }, 500)
    },
    closefun() {
      this.vedio = ''
    },
    // 预览视频
    preViewVideo(src) {
      this.previewSrc = typeof src === 'string' ? src : ''
    },
    //预览
    preview(row) {
      // scope.row.name, scope.row.url, scope.row.audio_id
      if (this.post.content_type == '4') {
        this.preViewVideo(row.content_url)
      } else if (this.post.content_type == '3') {
        this.vedio = row.homework_result_content_id
        this.openAudio(row.file_name, row.content_url)
      }
    },
    //删除
    del(val) {
      if (val != 2) {
        this.homework_result_content_ids = [val]
      }
      if (this.homework_result_content_ids.length > 0) {
        this.$confirm(
          '删除后学员查看作业时无法查看该文件，无法恢复！是否确定删除？',
          '删除文件',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.$http({
            url: '/DataBank/delHomework',
            data: {
              homework_result_content_ids: this.homework_result_content_ids,
            },
            callback: ({ code, info }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: info,
                  type: 'success',
                })
                this.$refs.childDialogBox.reset()
                this.getJgInfo()
              }
            },
          })
        })
      }
    },

    rowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },

    sortChange(value) {
      this.post = _.assign({}, this.post, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order
          ? ''
          : value.prop == 'size'
          ? 1
          : value.prop == 'file_name'
          ? 3
          : 2,
      })
    },
    handleSelectionChange(val) {
      this.homework_result_content_ids = val.map(
        item => item.homework_result_content_id
      )
    },
    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
#workMaterial {
  padding: 0 20px;
  .muisctip {
    position: absolute;
    top: 33%;
    left: 23%;
    img {
      width: 30px !important;
      height: 30px !important;
    }
  }
  .pictureyulan {
    position: absolute;
    left: 0;
    top: 3px;
  }
  ::v-deep .el-image__preview {
    opacity: 0;
  }
  .TitleVideo {
    display: flex;

    .imgVideo {
      // background: #000;
      margin-right: 10px;
      width: 141px;
      height: 80px;
      position: relative;
      overflow: hidden;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      .img {
        border-radius: initial;
        margin: 0 auto;
        width: auto;
        height: 100%;
        // margin: 0 auto;
        // margin: 0;
      }

      .encryption {
        position: absolute;
        top: 0;
        width: 45px;
        height: 22px;
        border-radius: 0px 0px 11px 0px;
        overflow: hidden;
        background: #fa6400;

        span {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 18px;
          margin-left: 4px;
        }
      }

      .videoTime {
        width: 100%;
        height: 20px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        text-align: center;

        p {
          height: 12px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }

    .textVideo {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      p {
        min-width: 168px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #333333;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h4 {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #999999;
        line-height: 14px;
        margin-top: 15px;
        font-weight: normal;
      }

      h5 {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #999999;
        line-height: 14px;
        margin-top: 13px;
        font-weight: normal;
        img {
          margin-right: 6px;
          height: 8px;
          width: 8px;
        }
      }
    }
  }
  .input-with-select {
    & ::v-deep .el-input__inner {
      width: 218px;
    }
  }
  ::v-deep .selectstyle {
    & .el-input__inner {
      width: 100px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    .left {
      display: flex;
      align-items: center;
      .boxTest {
        flex: 1;

        // margin-top: 20px;
        .t1 {
          width: 228px;
          display: flex;
          align-items: center;

          .c1 {
            flex: 1;
            color: #333333;
            font-size: 13px;
          }

          .c2 {
            font-size: 13px;
            font-weight: bold;
            color: #0aa29b;
            font-size: 13px;
            cursor: pointer;
          }
        }

        .t3 {
          width: 228px;
          margin-top: 14px;
        }
      }
    }
    .input {
      .el-input-group__prepend {
        width: 55px;
      }
    }
  }
  .main {
    position: relative;
  }
  .btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .driver {
      height: 14px;
      width: 1px;
      background-color: #0aa29b;
      margin: 0 10px;
    }
  }
  ::v-deep .footer {
    position: absolute;
    top: -65px;
    left: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    .font1 {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      margin-right: 20px;
    }
  }
}
</style>
