<template>
  <div class="audioVideo">
    <!--上传-->
    <div class="header">
      <div class="boxTest">
        <div class="t1">
          <div
            class="c1"
            @click="posttype(1)"
            :class="{ isClick: post.type == 1 }"
          >
            <p>视频</p>
          </div>
          <div
            class="c2"
            @click="posttype(2)"
            :class="{ isClick: post.type == 2 }"
          >
            <p>课程</p>
          </div>
          <div
            class="c3"
            @click="posttype(3)"
            :class="{ isClick: post.type == 3 }"
          >
            <p>文档</p>
          </div>
          <div
            class="c3"
            @click="posttype(7)"
            :class="{ isClick: post.type == 7 }"
          >
            <p>课件</p>
          </div>
          <div
            class="c4"
            @click="posttype(4)"
            :class="{ isClick: post.type == 4 }"
          >
            <p>图片</p>
          </div>
          <div
            class="c4"
            @click="posttype(5)"
            :class="{ isClick: post.type == 5 }"
          >
            <p>音频</p>
          </div>
          <div
            class="c5"
            @click="posttype(6)"
            :class="{ isClick: post.type == 6 }"
          >
            <p>直播</p>
          </div>
        </div>
      </div>
      <div class="store" v-if="post.type == 1">
        <el-select v-model="post.video_type" placeholder="全部">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="store" v-if="post.type == 2">
        <el-select v-model="post.course_type" placeholder="全部">
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="store" v-if="post.type == 3">
        <el-select v-model="post.format" placeholder="全部类型">
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="store" v-if="post.type == 7">
        <el-select v-model="post.format" placeholder="全部类型">
          <el-option
            v-for="item in options7"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="store" v-if="post.type == 4">
        <el-select v-model="post.format" placeholder="全部格式">
          <el-option
            v-for="item in options4"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <!-- <div class="store" v-if="post.type == 5">
        <el-select v-model="post.format" placeholder="全部格式">
          <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div> -->

      <div class="input">
        <el-input
          placeholder="请输入文件名称搜索"
          v-model="post.search"
          class="input-with-select"
        >
          <!-- 搜索 -->
        </el-input>
      </div>
    </div>
    <div class="contentVideo">
      <div class="rightMenu">
        <!-- 右侧内容视频 列表- 分页 -->
        <div class="rightCon">
          <pagination2
            :option="post"
            url="/DataBank/recycleBin"
            ref="childRecoveryVideo"
          >
            <template v-slot:default="{ tableData }">
              <el-table
                :data="tableData"
                @sort-change="sortChange"
                @selection-change="handleSelectionChange"
                max-height:200
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                  prop="file_name"
                  :label="
                    post.type == 1
                      ? '文件名称'
                      : post.type == 2 || post.type == 6
                      ? '课程'
                      : post.type == 3
                      ? '文档'
                      : post.type == 4
                      ? '文件'
                      : post.type == 7
                      ? '课件'
                      : '文件'
                  "
                  min-width="250"
                >
                  <template slot-scope="scope">
                    <div
                      class="TitleVideo"
                      v-if="post.type == 1 || post.type == 2 || post.type == 6"
                    >
                      <!-- 资料显示 -->
                      <div class="imgVideo" v-if="post.type == 1">
                        <img
                          :src="
                            scope.row.video_type == 1
                              ? require('../../assets/img/1.3.9/icon_spbs.png')
                              : require('../../assets/img/1.3.9/icon_spbsR.png')
                          "
                          alt=""
                        />

                        <div class="encryption" v-if="scope.row.zm_spec">
                          <span>{{ scope.row.zm_spec | getzm_spec }}</span>
                        </div>
                        <div class="videoTime">
                          <p>{{ scope.row.duration | formatTimeLength }}</p>
                        </div>
                      </div>

                      <!-- 课程显示 -->
                      <div
                        class="imgVideo"
                        v-if="post.type == 2 || post.type == 6"
                      >
                        <img
                          :src="
                            scope.row.photo
                              ? scope.row.photo
                              : require('../../assets/img/1.3.9/icon_spbs.png')
                          "
                          alt=""
                        />
                      </div>

                      <div class="textVideo">
                        <div>
                          <p
                            :title="
                              post.type == 1
                                ? scope.row.file_name
                                : scope.row.name
                            "
                          >
                            {{
                              post.type == 1
                                ? scope.row.file_name
                                : scope.row.name
                            }}
                          </p>
                          <h5 v-if="post.type == 1">
                            <div
                              class="flex-center"
                              v-if="scope.row.video_type != 6"
                            >
                              <img
                                v-if="scope.row.is_video_encryption == 1"
                                src="../../assets/img/1.3.9.4/icon_dg.png"
                                alt=""
                              />
                              <img
                                v-else-if="scope.row.is_video_encryption == 2"
                                src="../../assets/img/1.3.9.4/icon_cw.png"
                                alt=""
                              />
                              <img
                                style="
                                  height: 10px;
                                  width: 10px;
                                  margin-top: 2px;
                                "
                                v-else-if="scope.row.is_video_encryption == 3"
                                src="../../assets/img/1.3.9.4/icon_zmz.png"
                                alt=""
                              />
                              <span v-if="scope.row.is_video_encryption == 1">
                                已加密
                              </span>
                              <span v-if="scope.row.is_video_encryption == 2">
                                未加密
                              </span>
                              <span v-if="scope.row.is_video_encryption == 3">
                                加密中
                              </span>
                            </div>
                            <!-- 1未转码  2已转码  3转码中 -->
                            <img
                              class="ml20"
                              v-if="scope.row.transcode_status == 2"
                              src="../../assets/img/1.3.9.4/icon_dg.png"
                              alt=""
                            />
                            <img
                              class="ml20"
                              v-else-if="scope.row.transcode_status == 1"
                              src="../../assets/img/1.3.9.4/icon_cw.png"
                              alt=""
                            />
                            <img
                              v-else-if="scope.row.transcode_status == 3"
                              class="ml20"
                              style="height: 10px; width: 10px; margin-top: 2px"
                              src="../../assets/img/1.3.9.4/icon_zmz.png"
                              alt=""
                            />
                            <span v-if="scope.row.transcode_status == 2">
                              已转码
                            </span>
                            <span v-if="scope.row.transcode_status == 1">
                              未转码
                            </span>
                            <span v-if="scope.row.transcode_status == 3">
                              转码中
                            </span>
                          </h5>
                          <!-- <h5 v-if="post.type == 1">
                            状态: 
                            <span style="color:#FF3535" v-if="scope.row.is_video_encryption == 1">
                              已加密
                            </span>
                            <span style="color:#2791FB" v-if="scope.row.is_video_encryption == 2">
                              未加密
                            </span>
                            <span style="color:#FF3535" v-if="scope.row.is_video_encryption == 3">
                              加密中
                            </span>
                          </h5> -->
                        </div>
                      </div>
                    </div>

                    <div
                      class="TitleVideo_data"
                      v-else-if="post.type == 3 || post.type == 7"
                    >
                      <div class="imgVideo">
                        <img
                          src="../../assets/img/1.4.0.2/icon_zlbs@2x.png"
                          alt=""
                        />
                      </div>
                      <div class="textVideo">
                        <p :title="scope.row.name">
                          {{ scope.row.name }}
                        </p>
                      </div>
                    </div>

                    <div class="TitleVideo_image" v-else-if="post.type == 4">
                      <div class="imgVideo" style="height: 80px">
                        <!-- <img
                          src="../../assets/img/1.4.0.2/icon_zlbs@2x.png"
                          alt=""
                        /> -->
                        <el-image
                          v-if="scope.row.url"
                          style="width: 80px; height: 80px"
                          :src="scope.row.url"
                          :preview-src-list="[scope.row.url]"
                          fit="contain"
                        >
                          <div
                            slot="error"
                            class="image-slot"
                            style="text-align: center"
                          >
                            <i
                              style="margin-top: 33px"
                              class="el-icon-picture-outline"
                            ></i>
                          </div>
                        </el-image>
                      </div>
                      <div class="textVideo">
                        <p :title="scope.row.name">
                          {{ scope.row.name }}
                        </p>
                      </div>
                    </div>

                    <div class="TitleVideo_audio" v-else-if="post.type == 5">
                      <div
                        class="imgVideo"
                        @click="
                          openAudio(
                            scope.row.name,
                            scope.row.url,
                            scope.row.video_recycle_id
                          )
                        "
                      >
                        <img
                          src="../../assets/img/1.4.0.4/icon_yptb@2x.png"
                          alt=""
                        />
                        <img
                          :id="'audio' + scope.row.video_recycle_id"
                          class="audio1410"
                          style="display: none"
                          src="../../assets/img/1.4.0.4/icon_bfz@2x.png"
                        />
                      </div>
                      <div class="textVideo">
                        <p :title="scope.row.name">
                          {{ scope.row.name }}
                        </p>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="type"
                  label="类型"
                  v-if="
                    post.type == 3 ||
                      post.type == 4 ||
                      post.type == 5 ||
                      post.type == 7
                  "
                >
                  <template slot-scope="scope">
                    {{ scope.row.format }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="data_size"
                  sortable="custom"
                  label="大小"
                  v-if="
                    post.type == 3 ||
                      post.type == 4 ||
                      post.type == 5 ||
                      post.type == 7
                  "
                >
                  <template slot-scope="scope">{{ scope.row.size }}MB</template>
                </el-table-column>

                <el-table-column
                  prop="file_size"
                  label="大小"
                  v-if="post.type == 1"
                >
                  <!-- 资料显示 -->
                  <template slot-scope="scope">
                    <el-popover
                      v-if="
                        scope.row.is_video_encryption == 1 ||
                          scope.row.transcode_status == 2
                      "
                      placement="bottom"
                      width="200"
                      trigger="hover"
                    >
                      <div>
                        <div style="margin-bottom: 10px">
                          源视频(MB)：{{ scope.row.video_size }}
                        </div>
                        <div>转码视频(MB)：{{ scope.row.zm_video_size }}</div>
                      </div>
                      <div slot="reference" class="cp">
                        {{ scope.row.size }}MB
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </el-popover>
                    <div v-else>{{ scope.row.size }}MB</div>
                    <!-- <div class="VideoSizeBox">
                      <p>
                        {{ scope.row.video_size ? scope.row.video_size : 0 }}
                        <a v-if="scope.row.is_video_encryption == 1">
                          - 原视频
                        </a>
                      </p>
                      <p v-if="scope.row.is_video_encryption == 1">
                        {{
                          scope.row.zm_video_size ? scope.row.zm_video_size : 0
                        }}
                        <a>- 加密视频</a>
                      </p>
                    </div> -->
                  </template>
                </el-table-column>

                <el-table-column
                  prop="type"
                  label="累计收入(元)"
                  v-if="post.type == 2 || post.type == 6"
                >
                  <!-- 课程显示 -->
                  <template slot-scope="scope">
                    {{ scope.row.courseprice }}
                  </template>
                </el-table-column>

                <el-table-column prop="type" label="类型" v-if="post.type == 1">
                  <!-- 资料显示 -->
                  <template slot-scope="scope">
                    {{
                      scope.row.video_type == 1
                        ? '视频'
                        : scope.row.video_type == 6
                        ? '公开课回放'
                        : '直播课回放'
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="type"
                  label="类型"
                  v-if="post.type == 2 || post.type == 6"
                >
                  <!-- 课程显示 -->
                  <template slot-scope="scope">
                    <span v-if="scope.row.course_type">
                      {{ scope.row.course_type | getType }}
                    </span>
                    <span v-if="scope.row.live_mode">
                      <span v-if="scope.row.live_mode == 3">智能直播</span>
                      <span v-else>公开课</span>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="createtime"
                  label="创建时间"
                  v-if="post.type == 2 || post.type == 6"
                >
                  <!-- 课程显示 -->
                  <template slot-scope="scope">
                    {{
                      scope.row.createtime | formatTimeStamp('yyyy-MM-dd hh:mm')
                    }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="d_time"
                  label="删除时间"
                  sortable="custom"
                >
                  <!-- 都显示 -->
                  <template slot-scope="scope">
                    {{ scope.row.d_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
                </el-table-column>

                <el-table-column prop="uname" label="操作人">
                  <!-- 都显示 -->
                  <template slot-scope="scope">
                    {{ scope.row.uname }}
                  </template>
                </el-table-column>

                <el-table-column label="操作" min-width="110">
                  <template slot-scope="scope">
                    <div class="mouseRight">
                      <div class="btns" style="width: 109px">
                        <el-button
                          type="text"
                          @click="
                            cilckRecovery(
                              post.type == 1 ||
                                post.type == 3 ||
                                post.type == 4 ||
                                post.type == 5 ||
                                post.type == 7
                                ? scope.row.video_recycle_id
                                : scope.row.course_recycle_id
                            )
                          "
                        >
                          还原
                        </el-button>
                        <template>
                          <span class="space"></span>
                          <span
                            class="text"
                            @click="
                              DelDel(
                                post.type == 1 ||
                                  post.type == 3 ||
                                  post.type == 4 ||
                                  post.type == 5 ||
                                  post.type == 7
                                  ? scope.row.video_recycle_id
                                  : scope.row.course_recycle_id
                              )
                            "
                          >
                            彻底删除
                          </span>
                        </template>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <template v-slot:footer>
              <div class="footer">
                <span>已选择{{ selected.length || 0 }}条</span>
                <el-button
                  class="ml20"
                  type=""
                  @click="removeIs"
                  :disabled="selected.length == 0"
                  size="medium"
                >
                  批量还原
                </el-button>

                <el-button
                  type=""
                  size="medium"
                  :disabled="selected.length == 0"
                  @click="dels"
                >
                  批量彻底删除
                </el-button>
              </div>
            </template>
          </pagination2>
          <!--分页-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'audioVideo',

  components: {},

  data() {
    return {
      videoIdArr: [],
      isRemoveViedoId: [],
      isBg: true,
      selected: '',
      options: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '1',
          label: '视频',
        },
        {
          value: '2',
          label: '直播课回放',
        },
        {
          value: '6',
          label: '公开课回放',
        },
      ],
      options2: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '1',
          label: '直播',
        },
        {
          value: '2',
          label: '小班',
        },
        {
          value: '3',
          label: '录播',
        },
        {
          value: '4',
          label: '系列',
        },
      ],
      options3: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: 'ppt/pptx',
          label: 'ppt/pptx',
        },
        {
          value: 'doc/docx',
          label: 'doc/docx',
        },
        {
          value: 'xls/xlsx',
          label: 'xls/xlsx',
        },
        {
          value: 'pdf',
          label: 'pdf',
        },
        {
          value: 'zip',
          label: 'zip',
        },
        {
          value: 'rar',
          label: 'rar',
        },
        {
          value: 'xmind',
          label: 'xmind',
        },
        {
          value: 'txt',
          label: 'txt',
        },
      ],
      options7: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: 'ppt/pptx',
          label: 'ppt/pptx',
        },
        {
          value: 'pdf',
          label: 'pdf',
        },
      ],
      options4: [
        {
          value: '0',
          label: '全部格式',
        },
        {
          value: 'jpg',
          label: 'jpg',
        },
        {
          value: 'jpeg',
          label: 'jpeg',
        },
        {
          value: 'png',
          label: 'png',
        },
      ],
      options5: [
        {
          value: '0',
          label: '全部格式',
        },
        {
          value: 'mp3',
          label: 'mp3',
        },
        {
          value: 'wma',
          label: 'wma',
        },
        {
          value: 'aac',
          label: 'aac',
        },
      ],
      tableData: [],
      post: {
        type: 1,
        course_type: '0',
        video_type: '0',
        search: '',
        sort: '',
        format: '0',
      },
      total: 0,
      useStorage: 0,
      jigouStorage: 0,
      leftStorage: 0,
      uploadStart: false,
    }
  },

  computed: {
    percentage() {
      return this.useStorage === 0
        ? 0
        : Math.floor((this.useStorage / this.jigouStorage) * 100)
    },
    ...mapState(['userInfo']),
  },

  created() {
    this.getData()
  },

  methods: {
    openAudio(name, url, id) {
      this.$notify.closeAll()
      let docId = 'audio' + id
      document.querySelector('#' + docId).style.display = 'block'
      this.$notify({
        customClass: 'popAudio1404',
        offset: 100,
        duration: 0,
        title: '正在播放:' + name,
        dangerouslyUseHTMLString: true,
        message: `<audio class="audio" src=${encodeURI(
          url
        )} autoplay controls="controls">`,
        onClose: this.closefun,
      })
      setTimeout(() => {
        document.querySelector('.el-notification__title').title = name
      }, 500)
    },
    closefun() {
      let audio = document.getElementsByClassName('audio1410')
      for (var i = 0; i < audio.length; i++) {
        audio[i].style.display = 'none'
      }
    },
    posttype(type) {
      if (type != 5) {
        this.$notify.closeAll()
      }

      console.log(type)
      this.post.type = type
      this.$refs.childRecoveryVideo.reset()
    },
    DelDel(id) {
      if (this.$store.state.userInfo.version_id == 1) {
        this.$root.toggleUpdatePopup()
        return false
      } else {
        let type =
          this.post.type == 3 ||
          this.post.type == 4 ||
          this.post.type == 5 ||
          this.post.type == 7
            ? 1
            : this.post.type
        this.$confirm(
          '是否将已选文件彻底删除？彻底删除后无法恢复！',
          '彻底删除文件',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.$http({
            url: '/DataBank/pdDelete',
            data: {
              ids: id,
              type: type,
            },
            callback: () => {
              this.$root.prompt({
                msg: '彻底删除成功',
                type: 'success',
              })
              this.$refs.childRecoveryVideo.reset()
            },
            error: () => {
              this.$root.prompt('彻底删除失败')
            },
          })
        })
      }
    },
    //单个还原
    cilckRecovery(id) {
      if (this.$store.state.userInfo.version_id == 1) {
        this.$root.toggleUpdatePopup()
        return false
      } else {
        let type =
          this.post.type == 3 ||
          this.post.type == 4 ||
          this.post.type == 5 ||
          this.post.type == 7
            ? 1
            : this.post.type
        this.$confirm('是否还原已选文件？', '还原文件', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$http({
            url: '/DataBank/reduction',
            data: {
              ids: id,
              type: type,
            },
            callback: () => {
              this.$root.prompt({
                msg: '还原成功!',
                type: 'success',
              })
              this.$refs.childRecoveryVideo.reset()
            },
            error: () => {
              this.$root.prompt('还原失败!')
            },
          })
        })
      }
    },
    getData() {},

    //批量删除
    dels() {
      this.DelDel(this.videoIdArr)
    },
    //批量移动
    removeIs() {
      this.cilckRecovery(this.videoIdArr)
    },
    //多选后触发
    handleSelectionChange(val) {
      this.videoIdArr = []
      this.selected = val
      //筛选出多选后视频id
      val.forEach(item => {
        this.videoIdArr.push(
          this.post.type == 1 ||
            this.post.type == 3 ||
            this.post.type == 4 ||
            this.post.type == 5 ||
            this.post.type == 7
            ? item.video_recycle_id
            : item.course_recycle_id
        )
      })
    },

    // 排序
    sortChange(value) {
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      if (
        this.post.type == 3 ||
        this.post.type == 4 ||
        this.post.type == 5 ||
        this.post.type == 7
      ) {
        if (value.prop == 'data_size') {
          this.post.order = 1
        } else {
          this.post.order = 2
        }
      }
      if (value.prop == 'd_time') {
        this.post.order = 2
      }

      this.post.sort = sort
    },
  },
  filters: {
    getzm_spec(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P '
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
        default:
          '-'
      }
      return text
    },
    getType(val) {
      if (val == 1) {
        return '直播课'
      }
      if (val == 2) {
        return '小班课'
      }
      if (val == 3) {
        return '录播课'
      }
      if (val == 4) {
        return '系列课'
      }
      if (val == 5) {
        return '公开课'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.TitleVideo_audio {
  display: flex;

  .imgVideo {
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img:nth-child(1) {
      margin: 0 auto;
      width: 47px;
      height: 60px;
      margin: 0 auto;
    }

    img:nth-child(2) {
      position: absolute;
      top: 15px;
      width: 30px;
      height: 30px;
      left: 10px;
    }
  }

  .textVideo {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;

    p {
      min-width: 168px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 17px;
    }
  }
}

.TitleVideo_image {
  display: flex;

  .imgVideo {
    ::v-deep .el-icon-circle-close:before {
      content: '\E78D';
      color: #fff;
    }

    ::v-deep.el-image-viewer__mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.8;
      background: #000;
    }
  }

  .textVideo {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;

    p {
      min-width: 168px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 80px;
      margin-left: 5px;
    }
  }
}

.TitleVideo_data {
  display: flex;

  .imgVideo {
    margin-right: 10px;
    position: relative;
    overflow: hidden;

    img {
      margin: 0 auto;
      width: 47px;
      height: 60px;
      margin: 0 auto;
    }
  }

  .textVideo {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;

    p {
      min-width: 168px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 17px;
    }
  }
}

.isClick {
  background: #1b9d97 !important;
  color: #ffffff !important;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 12px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;
  position: absolute;
  top: -65px;
  left: -40px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.VideoSizeBox {
  p {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin: 10px 0;

    a {
      color: #999;
    }
  }
}

.TitleVideo {
  display: flex;

  .imgVideo {
    background: #000;
    margin-right: 10px;
    width: 141px;
    height: 80px;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    img {
      border-radius: initial;
      margin: 0 auto;
      width: auto;
      height: 100%;
      margin: 0 auto;
    }

    .encryption {
      position: absolute;
      top: 0;
      width: 45px;
      height: 22px;
      border-radius: 0px 0px 11px 0px;
      overflow: hidden;
      background: #fa6400;

      span {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 18px;
        margin-left: 4px;
      }
    }

    .videoTime {
      width: 100%;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      text-align: center;

      p {
        height: 12px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .textVideo {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    div {
      overflow: hidden;
    }

    p {
      min-width: 98px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h5 {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 14px;
      margin-top: 13px;
      font-weight: normal;

      img {
        margin-right: 6px;
        height: 8px;
        width: 8px;
      }
    }
  }
}

.contentVideo {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .rightMenu {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    padding: 0px 20px 0px 20px;
  }
}

.audioVideo {
  background: #fff;
}

::v-deep .audioVideo .el-table {
  padding: 0 20px;
}

.header {
  display: flex;
  margin-bottom: 60px;
  min-width: 900px;
  margin-top: 20px;
  padding: 0 20px 0 20px;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    // margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .boxTest {
    flex: 1;

    // margin-top: 20px;
    .t1 {
      width: 380px;
      display: flex;
      align-items: center;
      text-align: center;
      border-radius: 0px 4px 4px 0px;
      border: 1px solid #e0e3ea;

      border: 1px solid #e0e3ea;

      p {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 40px;
      }

      .c1 {
        flex: 1;
        height: 40px;
        width: 131px;
        font-size: 13px;
        border-radius: 4px 0px 0px 4px;
        color: #333333;
        background: #fff;
        cursor: pointer;
      }

      .c2 {
        flex: 1;
        background: #fff;
        height: 40px;
        color: #333333;
        font-size: 13px;
        cursor: pointer;
        border-left: 1px solid #e0e3ea;
      }

      .c3 {
        flex: 1;
        background: #fff;
        height: 40px;
        color: #333333;
        font-size: 13px;
        cursor: pointer;
        border-left: 1px solid #e0e3ea;
        border-right: 1px solid #e0e3ea;
      }

      .c4 {
        flex: 1;
        background: #fff;
        height: 40px;
        color: #333333;
        font-size: 13px;
        cursor: pointer;
        border-right: 1px solid #e0e3ea;
      }

      .c5 {
        flex: 1;
        background: #fff;
        height: 40px;
        color: #333333;
        font-size: 13px;
        cursor: pointer;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  .store {
    width: 160px;
    height: 36px;
    border-radius: 4px;
  }

  .input {
    margin-left: 20px;
  }
}

.btns {
  @extend %btns;
  position: relative;
}

.moreRightMenuAll {
  width: 136px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  position: absolute;
  left: -45px;
  z-index: 9;
  display: none;

  div {
    height: 40px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    p {
      margin-left: 20px;
      line-height: 40px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #4a4a4a;
    }
  }
}
</style>
