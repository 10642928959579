<template>
    <div class="videoAll">
        <audio-video></audio-video>
    </div>
</template>
<script>
import audioVideo from '../audioVideo'
export default {
    components:{
        audioVideo
    }
}
</script>