<template>
  <div class="textFileALL" :class="{ fixed: isWindowsAppV2 }">
    <!--上传-->
    <!-- <help
      class="assistant-ps"
      text="全部文档包含所有分组的视频，文档移动到其他分组后，依然还在全部文档分组下，但不会重复占用存储容量"
    ></help> -->
    <upload
      :class="{ uploadCourseFile: isWindowsAppV2 }"
      v-if="uploadStart"
      :leftStorage="leftStorage"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      @getJgInfo="getJgInfo"
      :list="post.group_id"
      :menuName="isMenuName"
      :typeselection="1"
      :uploadType="4"
      :title="'上传课件(多选)'"
    ></upload>
    <!--typeselection 1为可多选-->

    <div class="header">
      <div title="点击上传" class="uploading" @click="uploadvideo">
        上传课件
      </div>
      <div class="boxTest">
        <div class="t1">
          <div class="c1" v-if="userInfo.version_id == 4">
            存储空间：{{ useStorage }}G
          </div>
          <div class="c1" v-else>
            存储空间：{{ useStorage }}G / {{ jigouStorage }}G
          </div>
          <div
            v-if="userInfo.version_id !== 4 && !isWindowsAppV2"
            class="c2"
            @click="$root.toggleUpdatePopup"
          >
            扩容
          </div>
        </div>
        <div class="t3">
          <el-progress
            :percentage="percentage"
            color="#0aa29b"
            :show-text="false"
          ></el-progress>
        </div>
      </div>

      <div class="store">
        <el-select v-model="post.format" placeholder="全部类型">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="input">
        <el-input
          :placeholder="
            post.searchType == 1 || post.searchType == ''
              ? '请输入课件名称搜索'
              : '请输入上传者名称搜索'
          "
          v-model="post.search"
          class="input-with-select"
        >
          <el-select
            v-model="post.searchType"
            slot="prepend"
            placeholder="课件"
          >
            <el-option label="课件" value="1"></el-option>
            <el-option label="上传者" value="2"></el-option>
          </el-select>
          <!-- 搜索 -->
        </el-input>
      </div>
    </div>
    <div class="contentVideo">
      <!-- 左侧菜单 -->
      <div style="heigth: 100%">
        <div class="leftMenu">
          <div class="footAddMenu" @click="AddMenu">
            <img src="../../assets/img/1.3.9/icon_xjfz备份.png" alt="" />
            <p>新建分组</p>
          </div>
          <div class="boxMenu">
            <transition-group name="flip-list">
              <div
                class="childMenu"
                v-for="(item, index) in menuList"
                :key="item.group_id"
                @click="cilckGroup(item.group_id, item.name)"
                @mouseleave="leaveGroup()"
                @mouseenter="mouseGroup(item.group_id)"
                :class="{ isbg: selectid == item.group_id }"
                :style="selectid == item.group_id ? '#F0F8FF' : ''"
              >
                <img
                  class="img"
                  src="../../assets/img/1.3.9/icon_wjj.png"
                  alt=""
                />
                <div class="flex-center namecount">
                  <div class="name" :title="`${item.name}(${item.count})`">
                    {{ item.name }}({{ item.count }})
                  </div>
                  <div class="contain-updown">
                    <div class="upDown" v-if="index != 0&&index!=1">
                      <div
                        class="up item"
                        @click.stop="
                          index == 1 ? '' : groupSort(item.group_id, 1)
                        "
                      >
                        <img
                          class="img2"
                          src="~@ass/img/1.4.4.9/icon_sy_zh.png"
                          alt=""
                          v-if="index == 1"
                        />
                        <img
                          class="img2"
                          src="~@ass/img/1.4.4.9/icon_sy.png"
                          alt=""
                          v-else
                        />
                      </div>
                      <div
                        class="down item"
                        @click.stop="
                          index == menuList.length - 1
                            ? ''
                            : groupSort(item.group_id, 2)
                        "
                      >
                        <img
                          class="img2"
                          src="~@ass/img/1.4.4.9/icon_xyzz_zh.png"
                          alt=""
                          v-if="index == menuList.length - 1"
                        />
                        <img
                          class="img2"
                          src="~@ass/img/1.4.4.9/icon_xy.png"
                          alt=""
                          v-else
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="hoverDDD el-icon-more-outline"
                  @click.stop="clickhoverDDD(item.group_id, index)"
                >
                  <div class="childchildMenu">
                    <div
                      @click.stop="reName(item.group_id, item.name)"
                      :class="{ disabled: item.group_id == 0 }"
                    >
                      <span>编辑</span>
                    </div>
                    <div
                      @click.stop="deleteMenu(item.name, item.group_id)"
                      :class="{ disabled: item.group_id == 0 }"
                    >
                      <span>删除</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <div class="fff"></div>
      </div>
      <div class="rightMenu">
        <!-- 右侧内容视频 列表- 分页 -->
        <div class="rightCon">
          <pagination2
            :option="post"
            url="/DataBank/uploaded"
            @complete="getFileList"
            ref="childData"
          >
            <template v-slot>
              <el-table
                :data="tableData"
                @sort-change="sortChange"
                @selection-change="handleSelectionChange"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column
                  v-if="!isWindowsAppV2"
                  type="selection"
                  width="55"
                ></el-table-column>

                <el-table-column
                  prop="file_name"
                  label="课件"
                  min-width="330"
                  sortable="custom"
                >
                  <template slot-scope="scope">
                    <div class="TitleVideo">
                      <div class="imgVideo">
                        <img
                          src="../../assets/img/1.4.0.2/icon_zlbs@2x.png"
                          alt=""
                        />
                      </div>
                      <div class="textVideo">
                        <template v-if="isWindowsAppV2">
                          <div
                            :title="scope.row.name"
                            style="
                              font-weight: bold;
                              margin-bottom: 5px;
                              color: #333;
                            "
                          >
                            {{ scope.row.name }}
                          </div>
                          <div>
                            格式：{{ scope.row.format }} | 上传者：{{
                              scope.row.uname
                            }}
                          </div>
                        </template>
                        <template v-else>
                          <div :title="scope.row.name" style="color: #333">
                            {{ scope.row.name }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <template v-if="!isWindowsAppV2">
                  <el-table-column prop="format" label="格式" min-width="80">
                    <template slot-scope="scope">
                      <div>{{ scope.row.format }}</div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="file_size"
                    label="大小"
                    sortable="custom"
                    min-width="80"
                  >
                    <template slot-scope="scope">
                      <div>{{ scope.row.size }}MB</div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="add_time"
                    label="上传时间"
                    sortable="custom"
                    min-width="110"
                  >
                    <template slot-scope="scope">
                      {{
                        scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="user" label="上传者" min-width="100">
                    <template slot-scope="scope">
                      <div>{{ scope.row.uname }}</div>
                    </template>
                  </el-table-column>
                </template>

                <el-table-column label="操作" fixed="right" min-width="95px">
                  <template slot-scope="scope">
                    <template v-if="isWindowsAppV2">
                      <div class="btns">
                        <el-button
                          v-if="!scope.row.proress || scope.row.proress === 100"
                          type="text"
                          @click="use(scope.row)"
                        >
                          使用课件
                        </el-button>
                        <el-button
                          v-else-if="scope.row.proress === -1"
                          type="text"
                          style="color: #fe0000"
                          @click="use(scope.row)"
                        >
                          下载失败，
                          <ins>重试</ins>
                        </el-button>
                        <el-button v-else type="text">
                          {{ scope.row.proress }}%
                        </el-button>
                      </div>
                    </template>
                    <template v-else>
                      <div class="btns">
                        <el-button
                          type="text"
                          @click="
                            clickBianji(
                              scope.row.file_id,
                              scope.row.group_id,
                              scope.row.name
                            )
                          "
                        >
                          编辑
                        </el-button>
                        <template
                          v-if="
                            showDownLoadBtn ||
                              showDeleteBtn ||
                              post.group_id != 0
                          "
                        >
                          <span class="space"></span>
                          <el-dropdown>
                            <span class="text">更多</span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item v-if="showDownLoadBtn">
                                <p
                                  @click="
                                    download(scope.row.url, scope.row.name)
                                  "
                                >
                                  下载
                                </p>
                              </el-dropdown-item>
                              <el-dropdown-item v-if="post.group_id != 0&&post.group_id != -1">
                                <p @click="removeIsMenu(scope.row.file_id)">
                                  移出该分组
                                </p>
                              </el-dropdown-item>
                              <el-dropdown-item v-if="showDeleteBtn">
                                <p @click="delVideo(scope.row.file_id)">删除</p>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <template v-if="!isWindowsAppV2" v-slot:footer>
              <div class="footer">
                <span>已选择{{ selected.length || 0 }}条</span>
                <el-button
                  class="ml20"
                  type=""
                  @click="removeIs"
                  :disabled="selected.length == 0"
                  size="medium"
                >
                  批量移动
                </el-button>

                <el-button
                  v-if="showDeleteBtn"
                  type=""
                  size="medium"
                  :disabled="selected.length == 0"
                  @click="dels"
                >
                  批量删除
                </el-button>
              </div>
            </template>
          </pagination2>
          <!--分页-->
        </div>
      </div>
    </div>

    <el-dialog
      title="新建分组"
      :visible.sync="AddMenudialogVisible"
      width="422px"
      :modal-append-to-body="!isWindowsAppV2"
      :close-on-click-modal="false"
    >
      <el-input
        type="text"
        placeholder="输入分组名称"
        v-model="AddMenutext"
        maxlength="10"
        show-word-limit
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddMenuCancel">取 消</el-button>
        <el-button type="primary" @click="AddMenuFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="编辑分组"
      :visible.sync="renameMenudialogVisible"
      width="422px"
      :modal-append-to-body="!isWindowsAppV2"
      :close-on-click-modal="false"
    >
      <el-input
        type="text"
        placeholder="重命名分组名称"
        v-model="renameMenutext"
        maxlength="10"
        show-word-limit
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameMenuCancel">取 消</el-button>
        <el-button type="primary" @click="renameMenuFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="删除分组"
      :visible.sync="deleteMenudialogVisible"
      width="422px"
      :modal-append-to-body="!isWindowsAppV2"
      :close-on-click-modal="false"
    >
      <p>
        是否删除
        <a style="color: red">{{ deleteMenuName }}</a>
        ，删除后分组内的视频会释放到全部中！
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteMenuCancel">取 消</el-button>
        <el-button type="primary" @click="deleteMenuFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="批量移动"
      :visible.sync="removeVideodialogVisible"
      width="422px"
      :modal-append-to-body="!isWindowsAppV2"
      :close-on-click-modal="false"
    >
      <el-select placeholder="请选择要移动到的分组" v-model="selectMenuId">
        <el-option
          v-for="item in menuListS"
          :disabled="item.isBg"
          :key="item.group_id"
          :label="item.name + '(' + item.count + ')'"
          :value="item.group_id"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <a class="aaaRed">* 同一个文件只支持在一个分组</a>
        <el-button @click="removeVideoCancel">取 消</el-button>
        <el-button type="primary" @click="removeVideoFix">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="编辑"
      :visible.sync="renameVideodialogVisible"
      width="522px"
      :modal-append-to-body="!isWindowsAppV2"
      :close-on-click-modal="false"
    >
      <div style="display: flex">
        <div
          style="
            width: 100px;
            line-height: 40px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #333333;
          "
        >
          文件名称
        </div>
        <el-input
          type="text"
          placeholder="输入新的文件名"
          v-model="renameVideotext"
          maxlength="50"
          show-word-limit
        ></el-input>
      </div>
      <div style="display: flex; margin-top: 30px; margin-bottom: 30px">
        <div
          style="
            width: 100px;
            line-height: 40px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #333333;
          "
        >
          所属分组
        </div>
        <el-select placeholder="请选择要移动到的分组" v-model="selectMenuId">
          <el-option
            v-for="item in menuListS"
            :disabled="item.isBg"
            :key="item.group_id"
            :label="item.name + '(' + item.count + ')'"
            :value="item.group_id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <a class="aaaRedRame">
          * 重命名不会修改文件格式，同一个课件只支持在一个分组
        </a>
        <el-button @click="renameVideoCancel">取 消</el-button>
        <el-button type="primary" @click="renameVideoFix">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import upload from '@cm/base/UploadtextFile'
// import download from 'downloadjs'

import { mapState } from 'vuex'

export default {
  name: 'textFileALL',

  components: {
    upload,
  },

  data() {
    return {
      selectid: 0,

      isMenuName: '全部视频',

      videoIdArr: [], //批量移动的视频id数组

      renameVideoId: '',

      renameVideotext: '',

      isRemoveViedoId: [],

      selectMenuId: '',

      deleteMenuName: '',

      deleteMenuId: '',

      reNameid: '',

      isBg: true,

      selectedNum: 0,

      selected: '',

      menuList: [],

      menuListS: [],

      AddMenutext: '',

      renameMenutext: '',

      AddMenudialogVisible: false,

      renameMenudialogVisible: false,

      deleteMenudialogVisible: false,

      removeVideodialogVisible: false,

      renameVideodialogVisible: false,

      options: [
        {
          value: '0',
          label: '全部格式',
        },
        {
          value: 'ppt/pptx',
          label: 'ppt/pptx',
        },
        {
          value: 'doc/docx',
          label: 'doc/docx',
        },
        {
          value: 'xls/xlsx',
          label: 'xls/xlsx',
        },
        {
          value: 'pdf',
          label: 'pdf',
        },
      ],

      tableData: [],

      post: {
        type: 7,
        searchType: '1',
        search: '',
        state: '0',
        group_id: 0,
        order: '',
        sort: '',
        format: '0',
      },

      total: 0,

      useStorage: 0,

      jigouStorage: 0,

      leftStorage: 0,

      uploadStart: false,
    }
  },

  computed: {
    percentage() {
      return this.useStorage === 0
        ? 0
        : Math.floor((this.useStorage / this.jigouStorage) * 100)
    },

    ...mapState(['userInfo', 'isWindowsAppV2']),

    showDownLoadBtn() {
      return this.$store.state.roots.includes(116)
    },

    showDeleteBtn() {
      return this.$store.state.roots.includes(117)
    },
  },

  created() {
    this.getJgInfo()
    //type=3用于获取 文档 菜单
    this.getGroupList(7)
  },

  methods: {
    groupSort(group_id, sort) {
      this.$http({
        url: '/dataBank/groupSort',
        data: {
          group_id: group_id,
          sort: sort,
        },
        callback: () => {
          this.getGroupList(7)
        },
      })
    },

    uploadvideo() {
      this.uploadStart = true
    },

    //下载
    download(src, fileName) {
      // console.log(src, fileName)
      // return
      // const xhr = new XMLHttpRequest()
      // xhr.open('POST', src)
      // xhr.responseType = 'blob'
      // xhr.send()
      // xhr.onload = function(e) {
      //   download(e.target.response, fileName)
      // }
      var donwn = src + '?attname=' + fileName
      window.open(donwn)
    },

    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },

    cilckGroup(id, name) {
      this.selectid = id
      this.isMenuName = name
      //选中菜单的id请求菜单数据
      this.post.group_id = id
    },

    //鼠标进入后颜色
    mouseGroup(id) {
      const index = this.menuList.findIndex(item => item.group_id == id)
      var mouse = []
      mouse = document.getElementsByClassName('hoverDDD')
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        mouse[i].style.display = 'none'
      }
      if (index == 0||index==1) {
        return
      } else {
        mouse[index].style.display = 'block'
      }
      var isgroup = document.getElementsByClassName('childMenu')[index]
      isgroup.style.color = '#0aa29b'
    },

    //鼠标离开后清除颜色
    leaveGroup() {
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        document.getElementsByClassName('hoverDDD')[i].style.display = 'none'
        document.getElementsByClassName('childMenu')[i].style.color = ''
        document.getElementsByClassName('childchildMenu')[i].style.display =
          'none'
      }
    },
    //点击小点弹出的菜单传入菜单的id和第几个
    clickhoverDDD(id, index) {
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        document.getElementsByClassName('childchildMenu')[i].style.display =
          'none'
      }
      document.getElementsByClassName('childchildMenu')[index].style.display =
        'block'
    },

    deleteMenu(fileName, id) {
      this.deleteMenudialogVisible = true
      this.deleteMenuName = fileName
      this.deleteMenuId = id
    },

    getGroupList(type) {
      this.$http({
        url: '/DataBank/groupList?type=' + type,
        callback: ({ data }) => {
          this.menuList = data
          var data2 = _.merge([], data)
          data2.shift()
          this.menuListS = data2
        },
      })
    },
    //批量删除
    dels() {
      this.delVideo(this.videoIdArr)
    },
    //批量移动
    removeIs() {
      this.removeVideo(this.videoIdArr)
    },
    //多选后触发
    handleSelectionChange(val) {
      this.videoIdArr = []
      this.selected = val
      this.selectedNum = val.length
      //筛选出多选后视频id
      val.forEach(item => {
        this.videoIdArr.push(item.file_id)
      })
    },

    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      if (value.prop == 'file_size') {
        order = 1
      } else if (value.prop == 'file_name') {
        order = 3
      } else {
        order = 2
      }
      this.post.order = order
      this.post.sort = sort
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.uploadStart = false
      this.$refs.childData.reset()
      // //type=1用于获取 视频 菜单
      this.getGroupList(7)
    },

    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },

    // 添加分组菜单
    AddMenu() {
      this.AddMenudialogVisible = true
    },

    AddMenuCancel() {
      this.AddMenudialogVisible = false
    },
    AddMenuFix() {
      this.AddMenutext = this.trimStr(this.AddMenutext)
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+$/g.test(this.AddMenutext) ||
        this.AddMenutext == ''
      ) {
        this.$root.prompt('命名格式仅支持中英文和数字，空格无效，不能为空')
      } else {
        this.$http({
          url: '/DataBank/addGroup',
          data: {
            name: this.AddMenutext,
            type: 7,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.getGroupList(7)
              this.AddMenutext = ''
              this.AddMenudialogVisible = false
            }
          },
        })
      }
    },
    //重命名分组菜单
    //点击编辑
    reName(id, name) {
      this.renameMenudialogVisible = true
      this.reNameid = id
      this.renameMenutext = name
    },
    renameMenuCancel() {
      this.renameMenudialogVisible = false
    },
    renameMenuFix() {
      this.renameMenutext = this.trimStr(this.renameMenutext)
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5]+$/g.test(this.renameMenutext) ||
        this.renameMenutext == ''
      ) {
        this.$root.prompt('命名格式仅支持中英文和数字，空格无效，不能为空')
      } else {
        this.$http({
          url: '/DataBank/setGroup',
          data: {
            name: this.renameMenutext,
            group_id: this.reNameid,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.getGroupList(7)
              this.renameMenutext = ''
              this.renameMenudialogVisible = false
            }
          },
        })
      }
    },
    // 删除分组
    deleteMenuCancel() {
      this.deleteMenudialogVisible = false
    },
    deleteMenuFix() {
      this.deleteMenudialogVisible = false
      this.$http({
        url: '/DataBank/delGroup',
        data: {
          group_id: this.deleteMenuId,
        },
        callback: ({ code }) => {
          if (code == 200) {
            this.getGroupList(7)
            this.$refs.childData.reset()
          }
        },
      })
    },

    //删除视频单个
    delVideo(id) {
      this.$confirm('是否删除已选课件？删除后可在回收站中查看！', '删除课件', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url: '/DataBank/deleteRecording',
          data: {
            ids: id,
            type: 7,
          },
          callback: () => {
            // this.tableData.splice(index, 1)
            // this.total -= 1
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.getJgInfo()
            this.$refs.childData.reset()
            this.getGroupList(7)
          },
          error: () => {
            this.$root.prompt('删除失败!')
          },
        })
      })
    },
    //移出该分组
    removeIsMenu(id) {
      this.$confirm(
        '确定后该课件会从当前分组消失，在全部课件分组下可查看到该文件！',
        '移出该分组',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/DataBank/moveGroup',
          data: {
            ids: id,
            type: 7,
            group_id: '0',
          },
          callback: () => {
            // this.tableData.splice(index, 1)
            // this.total -= 1
            this.$root.prompt({
              msg: '移出成功',
              type: 'success',
            })
            this.getJgInfo()
            this.$refs.childData.reset()
            this.getGroupList(7)
          },
          error: () => {
            this.$root.prompt('移出失败!')
          },
        })
      })
    },

    //移动视频
    removeVideo(id, group_id) {
      if (group_id == 0) {
        this.selectMenuId = ''
      } else {
        this.selectMenuId = group_id
      }

      this.isRemoveViedoId = id
      this.removeVideodialogVisible = true
    },
    removeVideoCancel() {
      this.removeVideodialogVisible = false
      this.selectMenuId = ''
    },
    removeVideoFix() {
      if (this.selectMenuId === '') {
        this.$confirm('请选择分组', '错误', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
      } else {
        this.$http({
          url: '/DataBank/moveGroup',
          data: {
            ids: this.isRemoveViedoId,
            type: 7,
            group_id: this.selectMenuId,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.selectMenuId = ''
              this.getGroupList(7)
              this.$refs.childData.reset()
              this.removeVideodialogVisible = false
            }
          },
        })
      }
    },
    //重命名视频
    renameVideo(id, name) {
      this.renameVideodialogVisible = true
      this.renameVideoId = id
      this.renameVideotext = name
    },
    renameVideoCancel() {
      this.renameVideodialogVisible = false
      this.renameVideotext = ''
      this.selectMenuId = ''
    },
    renameVideoFix() {
      this.renameVideotext = this.trimStr(this.renameVideotext)
      var regTextChar = new RegExp('[\\\\*:<>/|?""]')
      if (
        regTextChar.test(this.renameVideotext) ||
        this.renameVideotext == ''
      ) {
        this.$root.prompt('文件名不能为空且不能包含特殊符号：\\ /:*?"<>|')
      } else {
        // this.removeVideoFix()
        this.$http({
          url: '/DataBank/setFile',
          data: {
            id: this.renameVideoId,
            name: this.renameVideotext,
            group_id: this.selectMenuId,
            type: 7,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.$refs.childData.reset()
              this.getGroupList(7)
              this.renameVideodialogVisible = false
              this.renameVideotext = ''
              this.selectMenuId = ''
            }
          },
        })
      }
    },

    // 使用文件客户端回调
    fileUseCallback(fileId, i) {
      // console.log(fileId, i)
      const index = this.tableData.findIndex(item => fileId == item.file_id)
      if (index > -1) {
        this.tableData.splice(
          index,
          1,
          _.assign({}, this.tableData[index], { proress: Number(i) })
        )
      }
    },

    // 使用PPT
    use(val) {
      this.fileUseCallback(val.file_id, 0.1)
      this.$store.dispatch(
        'sendToWindowsMsg',
        Object.assign({}, val, { type: 14, callback: this.fileUseCallback })
      )
    },

    clickBianji(vid, mid, name) {
      // 点击编辑弹出重命名与移动分组
      if (mid == 0) {
        this.selectMenuId = ''
      } else {
        this.selectMenuId = mid
      }

      this.isRemoveViedoId = vid

      this.renameVideodialogVisible = true
      this.renameVideoId = vid
      this.renameVideotext = name
      if (mid == 0) {
        this.selectMenuId = ''
      } else {
        this.selectMenuId = mid
      }
      this.isRemoveViedoId = vid
    },

    // 获取文件列表数据
    getFileList(data) {
      this.tableData = data.data || []
    },
  },

  //过滤器
  filters: {
    getSize(val) {
      return val + 'MB'
    },
    getStr(str) {
      function strlen(str) {
        var len = 0
        for (var i = 0; i < str.length; i++) {
          var c = str.charCodeAt(i)
          //单字节加1
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            len++
          } else {
            len += 2
          }
        }
        return len
      }
      if (strlen(str) >= 20) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.35s;
}

::v-deep .dialogVisible2_class {
  &.el-dialog {
    width: 465px;
  }
}

.popover_main_center {
  // margin-bottom: 42px;
  margin-top: 28px;
  display: flex;
  align-items: center;

  .popover_main_center_font1 {
    margin-right: 21px;
    font-size: 14px;
    color: #333333;
  }

  .popover_main_center_font2 {
    text-align: center;
    font-size: 12px;
    color: #333333;
    line-height: 30px;
    width: 74px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    margin-left: 10px;
    cursor: pointer;
  }

  .noclik {
    color: #999999;
    border: 1px solid #eaeaea;
    cursor: no-drop;
  }
}

.dialogVisible2_main {
  .font1 {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
  }
}

.ppp {
  width: 90px;
  text-align: left;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

::v-deep .aaaRed {
  width: 166px;
  height: 16px;
  font-size: 12px;
  color: #ff3535;
  line-height: 16px;
  margin-right: 48px;
}

::v-deep .aaaRedRame {
  width: 166px;
  height: 16px;
  font-size: 12px;
  color: #ff3535;
  line-height: 16px;
  margin-right: 10px;
}

body {
  background: rgb(245, 245, 245) !important;
}

.el-dropdown-menu__item {
  width: 80px;
}

.adminPage {
  background: rgb(245, 245, 245);
}

::v-deep #app {
  background: rgb(245, 245, 245);
  min-height: 100%;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    color: #ff3535;
    line-height: 16px;
    margin-right: 180px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;
  position: absolute;
  top: 0px;
  left: -60px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.VideoSizeBox {
  height: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    margin: 10px 0;

    a {
      color: #999;
    }
  }
}

.TitleVideo {
  display: flex;
  align-items: center;

  .imgVideo {
    margin-right: 10px;
    position: relative;
    overflow: hidden;

    img {
      margin: 0 auto;
      width: 47px;
      height: 60px;
      margin: 0 auto;
    }
  }

  .textVideo {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    div {
      min-width: 168px;
      font-size: 14px;
      color: #999;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 20px;

  .boxMenu::-webkit-scrollbar {
    display: none;
  }

  .fff {
    width: 250px;
    height: 45px;
    visibility: hidden;
  }

  .leftMenu {
    width: 250px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    position: relative;
    max-height: 420px;
    min-height: 330px;
    padding-bottom: 80px;
    overflow-x: hidden;
    overflow-y: auto;
    @extend %scroll;
    margin-top: 60px;

    .boxMenu {
      margin-top: 36px;
      width: 250px;

      .childMenu {
        width: 250px;
        height: 36px;
        // background: #F0F8FF;
        cursor: pointer;

        .img {
          width: 15px;
          height: 14px;
          position: relative;
          top: 12px;
          left: 20px;
        }

        .namecount {
          height: 12px;
          font-size: 12px;
          // color: #0AA29B;
          position: relative;
          left: 40px;
          bottom: 1px;
          width: 164px;

          .name {
            line-height: 15px;
            width: 103px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 5px;
          }

          .contain-updown {
            display: none;
            .upDown {
              display: flex;
              align-items: center;

              .item {
                width: 20px;
                height: 20px;

                .img2 {
                  height: 100%;
                  width: 100%;
                }
              }

              .up {
                margin-right: 10px;
              }
            }
          }
        }

        .hoverDDD {
          display: none;
          width: 25px;
          height: 26px;
          margin-left: 210px;
          margin-top: -16px;
          position: relative;
        }

        .childchildMenu {
          position: absolute;
          z-index: 999;
          width: 100px;
          height: 88px;
          right: 0;
          box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          border: 1px solid #f7f7f7;
          display: none;

          div:hover {
            background-color: #f0f8ff;
          }

          div {
            width: 100px;
            height: 44px;
            background: #fff;
            text-align: center;

            span {
              font-size: 14px;
              color: #4a4a4a;
              line-height: 44px;
            }
          }
        }

        &:hover .contain-updown {
          display: block !important;
        }
      }
    }

    .footAddMenu {
      position: absolute;
      top: 0;
      width: 250px;
      height: 36px;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 11px;
        left: 82px;
      }

      p {
        height: 13px;
        font-size: 13px;
        color: #333333;
        line-height: 36px;
      }
    }
  }

  .rightMenu::-webkit-scrollbar {
    display: none;
  }

  .rightMenu {
    flex: 1;
    position: relative;
    padding-right: 20px;
    overflow-x: hidden;
    padding-top: 60px;
  }
}

.textFileALL {
  z-index: 70;
  background: #fff;
  &.fixed {
    overflow-y: auto;
  }
}

::v-deep .textFileALL .el-table {
  padding: 0 20px;
}

.header {
  display: flex; 
  min-width: 930px;
  margin-top: 20px;
  padding-right: 20px;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    // margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .boxTest {
    flex: 1;

    // margin-top: 20px;
    .t1 {
      width: 228px;
      display: flex;
      align-items: center;

      .c1 {
        flex: 1;
        color: #333333;
        font-size: 13px;
      }

      .c2 {
        font-size: 13px;
        font-weight: bold;
        color: #0aa29b;
        font-size: 13px;
        cursor: pointer;
      }
    }

    .t3 {
      width: 228px;
      margin-top: 14px;
    }
  }

  .store {
    width: 160px;
    height: 36px;
    border-radius: 4px;
    margin-right: 20px;
  }

  ::v-deep .input {
    .el-input-group__prepend {
      width: 55px;
    }
  }
}

.btns {
  @extend %btns;
  position: relative;

  p {
    width: 136px;
  }
}

.moreRightMenuAll {
  width: 136px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  position: absolute;
  left: -45px;
  z-index: 9;
  display: none;

  div {
    height: 40px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    p {
      margin-left: 20px;
      line-height: 40px;
      font-size: 14px;
      color: #4a4a4a;
    }
  }
}
.uploadCourseFile {
  overflow: hidden;
  ::v-deep .el-dialog {
    transform: scale(0.8);
    margin-top: 9.4vh !important;
    transform-origin: top center;
  }
}
</style>
